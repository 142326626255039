import React, { useState, useEffect, } from 'react'
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Row, Col } from 'react-bootstrap'
import AverageScore from '../components/students/AverageScore'
import TopStudent from '../components/TopStudent'
import GradeTableStudent from '../components/students/GradeTableStudent'
import { setSelectedGroup } from "../store/action";
import { handleUnauthorizedError } from "../functions/authUtils";
import Form from "react-bootstrap/Form";
import { fetchStudentGroups, fetchSettingsGrade } from "../functions/apiService";
import { OverlayTrigger, Popover } from "react-bootstrap";
import NotesTableStudent from '../components/students/NotesTableStudent';

function GradeStudent() {
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const [openSelectGroup, setOpenSelectGroup] = useState(false);
	const [groups, setGroups] = useState([]);
	const selectedGroup = useSelector((state) => state.selectedGroup);
	const [loading, setLoading] = useState(true);

	const handleGroupChange = (e) => {
		const groupId = e.target.value;
		dispatch(setSelectedGroup(groupId));
	};

	useEffect(() => {
		if (groups && groups.length > 0) {
			if (!selectedGroup) {
				dispatch(setSelectedGroup(groups[0].id));
			}
		}
	}, [groups, selectedGroup, dispatch]);

	useEffect(() => {
		const fetchData = async () => {
			try {
				const data = await fetchStudentGroups(navigate);
				setGroups(data);
			} catch (error) {
				handleUnauthorizedError(error, navigate);
			} finally {
				setLoading(false);
			}
		};

		fetchData();
	}, [navigate]);

	const [percents, setPercents] = useState({});

	useEffect(() => {
		const fetchData = async () => {
			try {
				const data = await fetchSettingsGrade(navigate);
				setPercents(data);
			} catch (error) {
				handleUnauthorizedError(error, navigate);
			} finally {
				setLoading(false);
			}
		};

		fetchData();
	}, []);

	const renderSchedule = (groupId) => {

		const selectedGroupData = groups.find((group) => group.id === Number(groupId));

		if (!selectedGroupData || !selectedGroupData.schedules || selectedGroupData.schedules.length === 0) {
			return <p>Cədvəl mövcud deyil</p>;
		}

		return (
			<table className="turn-wrapper">
				<tbody>
					{selectedGroupData.schedules.map((schedule, index) => (
						<tr key={index}>
							<td>
								<span className="turn-left">{schedule.week_day}</span>
							</td>
							<td>
								<span className="turn-right">
									<div>
										{schedule.time}
										{schedule.end_time && ` - ${schedule.end_time}`}
									</div>
								</span>
							</td>
						</tr>
					))}
				</tbody>
			</table>
		);
	};

	return (
		<section className="grade-student">
			<div className="grade-student__wrapper">
				<Row>
					<Col xs={12} md={8} lg={8}>
						<div className="group-subjectd-flex d-flex align-items-center mb-4">
							<p className="group-subject-name">Fənn: </p>
							<Form.Select
								className={`selection ${openSelectGroup ? "active" : ""}`}
								onClick={() => setOpenSelectGroup((prev) => !prev)}
								onChange={handleGroupChange}
								value={selectedGroup || ""}
							>
								{groups?.map((group) => (
									<option key={group.id} value={group.id}>
										{group.name}
									</option>
								))}
							</Form.Select>
							<div className="group-schedules">
								<OverlayTrigger
									placement="right"
									rootClose
									trigger="click"
									overlay={
										<Popover id="popover-positioned-right">
											<Popover.Body style={{ padding: "5px" }}>
												{renderSchedule(selectedGroup)}
											</Popover.Body>
										</Popover>
									}
								>
									<span className="d-inline-block">
										<button className="operation-btn gray-btn text-white">
											<svg
												xmlns="http://www.w3.org/2000/svg"
												viewBox="0 0 24 24"
												width="24"
												height="24"
												color="#fff"
												fill="none"
											>
												<circle
													cx="12"
													cy="12"
													r="10"
													stroke="currentColor"
													strokeWidth="1.5"
												/>
												<path
													d="M9.5 9.5L12.9999 12.9996M16 8L11 13"
													stroke="currentColor"
													strokeWidth="1.5"
													strokeLinecap="round"
													strokeLinejoin="round"
												/>
											</svg>
										</button>
									</span>
								</OverlayTrigger>
							</div>
						</div>
						<AverageScore />
						{percents && (
							<div className="grade-info">
								<span>Qiymət ortalamasının hesablanması</span>
								<span className="bordered">
									{Number.isInteger(parseFloat(percents.daily_percent))
										? parseInt(percents.daily_percent)
										: percents.daily_percent}% günlük
								</span>
								<span className="bordered">
									{Number.isInteger(parseFloat(percents.quiz_percent))
										? parseInt(percents.quiz_percent)
										: percents.quiz_percent}% quiz
								</span>
								<span className="bordered">
									{Number.isInteger(parseFloat(percents.project_percent))
										? parseInt(percents.project_percent)
										: percents.project_percent}% layihə
								</span>
								<span className="bordered">
									{Number.isInteger(parseFloat(percents.exam_percent))
										? parseInt(percents.exam_percent)
										: percents.exam_percent}% imtahan
								</span>
							</div>
						)}


						<GradeTableStudent />
						<NotesTableStudent />
					</Col>
					<TopStudent />
				</Row>

			</div>
		</section>
	)
}

export default GradeStudent
