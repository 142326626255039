import { useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import * as consts from "../../consts/Consts";
import {
	createHeaders,
} from "../../functions/apiService";
const AddRequestModal = ({ showModal, handleShowModal, role }) => {

	const [formData, setFormData] = useState({
		theme: "",
		type: "",
		message: "",
	});
	const [errors, setErrors] = useState({
		theme: false,
		type: false,
		message: false,
	});
	const [submitted, setSubmitted] = useState(false);

	const validateForm = () => {
		const newErrors = {};

		if (!formData.theme) {
			newErrors.theme = "Mövzu daxil edin";
		} else if (formData.theme.length > 64) {
			newErrors.theme = "Mövzu 64 simvoldan çox olmamalıdır.";
		}

		if (!formData.type) {
			newErrors.type = "Təklif və şikayəti daxil edin.";
		}

		if (!formData.message) {
			newErrors.message = "Mesajınızı daxil edin.";
		} else if (formData.message.length > 1000) {
			newErrors.message = "Mesajınız 1000 simvoldan çox olmamalıdır.";
		}

		setErrors(newErrors);
		return Object.keys(newErrors).length === 0;
	};

	const handleChange = (e) => {
		setFormData({
			...formData,
			[e.target.name]: e.target.value,
		});
	};

	const handleSubmit = async (e) => {
		const headers = createHeaders();
		e.preventDefault();
		setSubmitted(true);
		if (validateForm()) {
			try {
				const apiPath =
					role === "teacher"
						? `${consts.API_URL}/teacher/appeal`
						: `${consts.API_URL}/student/appeal`;

				const response = await fetch(apiPath, {
					method: 'POST',
					headers: headers,
					body: JSON.stringify(formData),
				});


				if (response.ok) {
					setFormData({
						theme: "",
						type: "",
						message: "",
					});
					// console.log("Form successfully submitted");
					handleShowModal();
				} else {
					// console.error("Error submitting form");
				}
			} catch (error) {
				// console.error("Error:", error);
			}
		}
	};

	return (
		<>
			<Modal show={showModal} onHide={handleShowModal}>
				<Modal.Header closeButton className="modal-header-divider">
					<div className="modal-title">
						<svg
							width="20"
							height="20"
							viewBox="0 0 20 20"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M4 9.48513L8.57372 14L16 5"
								stroke="#696974"
								strokeWidth="2"
								strokeLinecap="round"
								strokeLinejoin="round"
							/>
						</svg>
						<span>Yeni sorğu göndər</span>
					</div>
				</Modal.Header>
				<Modal.Body className="modal-body-divider">
					<div className="body-container">
						<h2 className="request-modal_title">Dəstək xidməti</h2>
						<h3 className="request-modal_subtitle">Təklif və ya şikayət</h3>

						<Form onSubmit={handleSubmit}>
							<Form.Group className="mb-4">
								<Form.Control
									type="text"
									name="theme"
									placeholder="Mövzu"
									value={formData.theme}
									onChange={handleChange}
									className={`pr-5 ${submitted && errors.theme ? "input-error is-invalid" : ""}`}
								/>
								{submitted && errors.theme && (
									<Form.Control.Feedback type="invalid">
										{errors.theme}
									</Form.Control.Feedback>
								)}
							</Form.Group>

							<Form.Group className="select-form mb-4">
								<div className="position-relative">
									<Form.Control
										as="select"
										name="type"
										value={formData.type}
										onChange={handleChange}
										className={`pr-5 ${submitted && errors.type ? "input-error" : ""}`}
									>
										<option value="">Siyahıdan seçin</option>
										<option value="suggestion">Təklif</option>
										<option value="remark">Şikayət</option>
									</Form.Control>
									<svg
										width="14"
										height="8"
										viewBox="0 0 14 8"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											d="M1 1L7 7L13 1"
											stroke="#6F767E"
											strokeWidth="2"
											strokeLinecap="round"
											strokeLinejoin="round"
										/>
									</svg>
								</div>
								{submitted && errors.type && (
									<Form.Control.Feedback type="invalid">
										{errors.type}
									</Form.Control.Feedback>
								)}
							</Form.Group>

							<Form.Group className="mb-4">
								<Form.Control
									as="textarea"
									name="message"
									placeholder="Mesajınızı yazın..."
									style={{ height: "155px", padding: "14px 20px" }}
									value={formData.message}
									onChange={handleChange}
									className={`pr-5 ${submitted && errors.message ? "input-error" : ""}`}
								/>
								{submitted && errors.message && (
									<Form.Control.Feedback type="invalid">
										{errors.message}
									</Form.Control.Feedback>
								)}
							</Form.Group>

							<Button className="modal-btn btn-green" type="submit">
								Sorğunu göndər
							</Button>
						</Form>
					</div>
				</Modal.Body>
			</Modal>
		</>
	);
};

export default AddRequestModal;
