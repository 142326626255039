import GraduatesTable from "../components/admin/GraduatesTable";

function Graduates() {
  return (
    <>
      <section>
        <h3 className="title mb-3">Məzunlar</h3>
        <GraduatesTable />
      </section>
    </>
  );
}

export default Graduates;
