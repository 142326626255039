// import { useNavigate } from "react-router-dom";

// // Хук для получения функции навигации
// const useNavigation = () => useNavigate();

// // Установка токена в localStorage
// const setToken = (tokenData) => {
//   localStorage.setItem("token", JSON.stringify(tokenData));
// };

// // Получение токена из localStorage
// const getToken = () => {
//   const tokenDataString = localStorage.getItem("token");
//   try {
//     const tokenData = JSON.parse(tokenDataString);
//     return tokenData ? tokenData : null;
//   } catch (error) {
//     return null;
//   }
// };

// // Сохранение токена в localStorage
// const saveToken = (token) => {
//   setToken({
//     value: token,
//   });
// };

// // Обработка неавторизованного доступа
// const handleUnauthorizedResponse = (navigate) => {
//   navigate("/");
// };

// // Проверка токена и навигация в случае отсутствия токена
// const checkTokenAndNavigate = (navigate) => {
//   const token = getToken();
//   if (!token) {
//     handleUnauthorizedResponse(navigate);
//   }
// };

// // Очистка токена из localStorage
// const clearToken = () => {
//   localStorage.removeItem("token");
// };

// export {
//   saveToken,
//   getToken,
//   clearToken,
//   handleUnauthorizedResponse,
//   useNavigation,
//   checkTokenAndNavigate,
// };


// tokenService.js

// Функция для установки значения cookie
export const setCookie = (name, value, days) => {
	const expires = new Date(Date.now() + days * 864e5).toUTCString();
	document.cookie = `${name}=${value}; expires=${expires}; path=/`;
};

// Функция для получения значения из cookie
export const getCookie = (name) => {
	const value = `; ${document.cookie}`;
	const parts = value.split(`; ${name}=`);
	if (parts.length === 2) return parts.pop().split(';').shift();
};

// Сохранение access_token и refresh_token в cookies
export const saveTokensToCookie = (accessToken, refreshToken, expiresIn) => {
	setCookie('access_token', accessToken, expiresIn);  // expiresIn в днях
	setCookie('refresh_token', refreshToken, 30);  // Храним refresh_token 30 дней
};

// Получение токенов из cookies
export const getAccessTokenFromCookie = () => getCookie('access_token');
export const getRefreshTokenFromCookie = () => getCookie('refresh_token');

// Функция для расшифровки токена (JWT)
export const parseJwt = (token) => {
	try {
		const base64Url = token.split('.')[1];
		const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
		const jsonPayload = decodeURIComponent(atob(base64).split('').map((c) => {
			return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
		}).join(''));

		return JSON.parse(jsonPayload);
	} catch (error) {
		return null;
	}
};

// Проверка, истек ли токен
// Пример функции isTokenExpired в tokenService.js
export const isTokenExpired = (token, bufferInSeconds = 0) => {
	const decodedToken = parseJwt(token);
	if (!decodedToken || !decodedToken.exp) return true;
	const expiryTime = decodedToken.exp * 1000;
	const currentTime = Date.now();
	return (expiryTime - currentTime) < bufferInSeconds * 1000;
};


// Очистка токенов из cookies
export const clearTokens = () => {
	document.cookie = 'access_token=; Max-Age=0; path=/';
	document.cookie = 'refresh_token=; Max-Age=0; path=/';
};
