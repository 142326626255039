import TeachersActivityTable from "../components/admin/TeachersActivityTable";

function TeachersActivity() {
  return (
    <>
      <section>
        <h3 className="title mb-3">Müəllimin aktivliyi</h3>
        <TeachersActivityTable/>
      </section>
    </>
  );
}

export default TeachersActivity;
