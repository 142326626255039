import React, { useState, useEffect, useRef } from "react";
import { Pagination } from "@mui/material";
import Form from "react-bootstrap/Form";
import NotesTableItemStudent from "./NotesTableItemStudent";
import { useSelector, useDispatch } from "react-redux";
import { setSelectedGroup } from "../../store/action";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import * as consts from "../../consts/Consts";
import { createHeaders } from "../../functions/apiService"

function NotesTableStudent({ role }) {
	const [openSelectOne, setOpenSelectOne] = useState(false);
	const [openSelectTwo, setOpenSelectTwo] = useState(false);
	const [currentWeek, setCurrentWeek] = useState(
		getCurrentWeekRange(new Date())
	);
	const [showCalendar, setShowCalendar] = useState(false);
	const [selectedDate, setSelectedDate] = useState(new Date());
	const calendarRef = useRef(null);
	const dispatch = useDispatch();
	const selectedGroup = useSelector((state) => state.selectedGroup);
	const [perPage, setPerPage] = useState(25);
	const [currentPage, setCurrentPage] = useState(1);
	const [totalPages, setTotalPages] = useState(0);
	const [getData, setGetData] = useState(null);
	const [allStudents, setAllStudents] = useState(null);
	const [selectedType, setSelectedType] = useState("daily");
	const [sortBy, setSortBy] = useState("full_name");
	const [sortDirection, setSortDirection] = useState("asc");

	const handlePrevWeek = () => {
		const prevWeekStart = parseDate(currentWeek.start);
		prevWeekStart.setDate(prevWeekStart.getDate() - 7);
		setCurrentWeek(getCurrentWeekRange(prevWeekStart));
	};

	const handleNextWeek = () => {
		const nextWeekStart = parseDate(currentWeek.start);
		nextWeekStart.setDate(nextWeekStart.getDate() + 7);
		setCurrentWeek(getCurrentWeekRange(nextWeekStart));
	};

	function getCurrentWeekRange(date) {
		const today = new Date(date);
		const dayOfWeek = today.getDay();

		const startOfWeek = new Date(today);
		startOfWeek.setDate(
			today.getDate() - dayOfWeek + (dayOfWeek === 0 ? -6 : 1)
		);

		const endOfWeek = new Date(startOfWeek);
		endOfWeek.setDate(startOfWeek.getDate() + 6);

		return {
			start: formatDate(startOfWeek),
			end: formatDate(endOfWeek),
		};
	}

	function formatDate(date) {
		return date.toLocaleDateString("ru-RU", {
			day: "2-digit",
			month: "2-digit",
			year: "numeric",
		});
	}

	function parseDate(dateString) {
		const [day, month, year] = dateString.split(".");
		return new Date(`${year}-${month}-${day}`);
	}

	const handleDateChange = (date) => {
		setSelectedDate(date);
		setCurrentWeek(getCurrentWeekRange(date));
		setShowCalendar(false);
	};

	const handleToggleCalendar = () => {
		setShowCalendar((prev) => !prev);
	};

	const handleClickOutside = (event) => {
		if (calendarRef.current && !calendarRef.current.contains(event.target)) {
			setShowCalendar(false);
		}
	};

	useEffect(() => {
		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, []);

	const filteredNotes = (getData || []).filter(note =>
		selectedGroup ? note.groupId === selectedGroup.id : true
	);


	const getFormattedDate = () => {
		const dateToFormat = selectedDate;

		return dateToFormat.toLocaleDateString("ru-RU", {
			day: "2-digit",
			month: "2-digit",
			year: "numeric",
		});
	};

    const handleTypeChange = (event) => {
        setSelectedType(event.target.value); // Status değiştiğinde güncelle
    };

    useEffect(() => {
        if (selectedGroup && selectedType) {
            const formattedDate = getFormattedDate();
            const dateToSend = currentWeek.start || formattedDate;
            const headers = createHeaders();

            fetch(
                `${consts.API_URL}/student/grade/get/note?pagination=${perPage}&page=${currentPage}&group_id=${selectedGroup}&date=${dateToSend}&status=${selectedType}`, // Status ekleme
                {
                    method: "GET",
                    headers: headers,
                }
            )
                .then((response) => {
                    if (!response.ok) {
                        throw new Error("Ошибка сети");
                    }
                    return response.json();
                })
                .then((data) => {
                    setGetData(data.data);
                    setTotalPages(data.pagination.total_pages);
                })
                .catch((error) => {
                    console.error("Ошибка при отправке запроса:", error);
                });
        }
    }, [
        selectedGroup,
        selectedType, // Burada da ekliyoruz
        selectedDate,
        currentWeek.start,
        perPage,
        currentPage,
        sortBy,
        sortDirection,
    ]);
	const handlePerPageChange = (event) => {
		setPerPage(Number(event.target.value));
		setCurrentPage(1);
	};

	const handlePageChange = (event, value) => {
		setCurrentPage(value);
	};


	const handleSortChange = (e) => {
		const value = e.target.value;
		const [field, direction] = value.split("=");
		if (field === sortBy) {
			setSortDirection((prevDirection) =>
				prevDirection === "asc" ? "desc" : "asc"
			);
		} else {
			setSortBy(field);
			setSortDirection(direction);
		}
	};


	return (
		<div className="groups">
			<div className="groups-header groups-header-grade">
				<div className="header-left">
					<h3 className="title mb-3">Müəllimin qeydləri</h3>
				</div>
				<div className="header-right">
					<div className="group-subject d-flex align-items-center">
						<p className="group-subject-name">Qiymət tipi: </p>
						<Form.Select
                            className='selection form-select'
                            value={selectedType}
                            onChange={handleTypeChange}
                        >
                            <option value="daily">Günlük</option>
                            <option value="exam">İmtahan</option>
                            <option value="quiz">Quiz</option>
                            <option value="project">Disiplin</option>
                        </Form.Select>
					</div>
					<div className="week-navigation">
						<button onClick={handlePrevWeek}>
							<svg
								width="7"
								height="12"
								viewBox="0 0 7 12"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									fillRule="evenodd"
									clipRule="evenodd"
									d="M1.8938 6.11523L6.04779 1.93164C6.35407 1.62207 6.35407 1.12402 6.04779 0.814453C5.90137 0.666016 5.70148 0.582031 5.4928 0.582031C5.28412 0.582031 5.08423 0.666016 4.93781 0.814453L0.227783 5.55664C-0.0759277 5.86816 -0.0759277 6.36426 0.227783 6.67578L4.93781 11.418C5.08447 11.5654 5.2843 11.6494 5.4928 11.6494C5.70129 11.6494 5.90112 11.5654 6.04779 11.418C6.3515 11.1064 6.3515 10.6104 6.04779 10.2998L1.8938 6.11523Z"
									fill="#707070"
								/>
							</svg>
						</button>
						<button className="week-current" onClick={handleToggleCalendar}>
							{currentWeek.start} - {currentWeek.end}
						</button>
						<button onClick={handleNextWeek}>
							<svg
								width="7"
								height="12"
								viewBox="0 0 7 12"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									fillRule="evenodd"
									clipRule="evenodd"
									d="M4.38379 6.11523L0.229736 1.93164C-0.0765381 1.62207 -0.0765381 1.12402 0.229736 0.814453C0.376221 0.666016 0.576172 0.582031 0.78479 0.582031C0.993408 0.582031 1.19336 0.666016 1.33972 0.814453L6.04968 5.55664C6.35339 5.86816 6.35339 6.36426 6.04968 6.6748L1.33972 11.418C1.19312 11.5654 0.993286 11.6494 0.78479 11.6494C0.576294 11.6494 0.376465 11.5654 0.229858 11.418C-0.0737305 11.1064 -0.0738526 10.6104 0.229858 10.2998L4.38379 6.11523Z"
									fill="#707070"
								/>
							</svg>
						</button>
					</div>
					{/* <Form.Select
						className={`selection ${openSelectOne ? "active" : ""}`}
						onClick={() => setOpenSelectOne((prev) => !prev)}
						onChange={handleSortChange}
						value={`${sortBy}${sortDirection ? `=${sortDirection}` : ""}`}
					>
						<option value="">Sırala</option>
						<option value="full_name=asc">Müəllim A-Z</option>
						<option value="full_name=desc">
						Müəllim Z-A
						</option>
						<option value="note=asc">Qeyd A-Z</option>
						<option value="note=desc">Qeyd Z-A</option>
						<option value="date=asc">Tarix A-Z</option>
						<option value="date=desc">Tarix Z-A</option>
					</Form.Select> */}
				</div>
			</div>
			<div className="groups-body">
				<table>
					<thead>
						<tr>
							<th>
								<div className="left column-content" style={{ width: "200px" }}>
									Tarix
								</div>
							</th>
							<th>
								<div className="column-content" style={{ width: "300px" }}>
									Qeyd
								</div>
							</th>
							<th>
								<div className="column-content">
									Müəllim
								</div>
							</th>
						</tr>
					</thead>
					<tbody>
						{filteredNotes.map((student, i) => (
							<NotesTableItemStudent data={student} key={i} role={role} />
						))}
					</tbody>
				</table>
			</div>
			{/* <div className="groups-footer">
				<div className="groups-pagination d-flex">
					<Pagination
						count={totalPages}
						page={currentPage}
						onChange={handlePageChange}
						className="pagination"
						variant="outlined"
						shape="rounded"
					/>

					<Form.Select
						className={`selection ${openSelectTwo ? "active" : ""}`}
						onClick={() => setOpenSelectTwo((prev) => !prev)}
						onChange={handlePerPageChange}
					>
						<option value="10">10</option>
						<option value="25">25</option>
						<option value="50">50</option>
						<option value="100">100</option>
					</Form.Select>
				</div>
			</div> */}
			{showCalendar && (
				<div className="calendar-popup">
					<DatePicker
						selected={selectedDate}
						onChange={handleDateChange}
						inline
					/>
				</div>
			)}
		</div>
	);
}

export default NotesTableStudent;
