import TemplatesTable from "../components/admin/TemplatesTable";

const Templates = () => {
  return (
    <>
      <section>
        <h3 className="title mb-3">Şablonlar</h3>
        <TemplatesTable/>
      </section>
    </>
  );
};

export default Templates;
