import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Form, Modal } from "react-bootstrap";
import { createHeaders, createSubjectPost } from "../../functions/apiService";
import { handleUnauthorizedError } from "../../functions/authUtils";
import * as consts from "../../consts/Consts";

const AddDirections = ({ show, handleClose, direction }) => {
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    name: "",
  });

  const [errors, setErrors] = useState({});

  const resetFormData = () => {
    setFormData({
      name: "",
    });
    setErrors({});
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    if (value.trim() && errors[name]) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: undefined,
      }));
    }
  };

  const validateForm = () => {
    const newErrors = {};

    if (!formData.name.trim()) {
      newErrors.name = "Tədrisin istiqamətini daxil edin";
    } else if (formData.name.length > 64) {
      newErrors.name = "Tədrisin istiqaməti 64 simvoldan çox olmamalıdır.";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleCloseModal = () => {
    resetFormData();
    handleClose();
  };


  const handleSubmit = async () => {
    if (validateForm()) {
      const headers = createHeaders();

      const dataToSend = {
        translations: [
          {
            language_id: 1,
            name: formData.name,
          },
        ],
      };

      try {
        const response = await fetch(`${consts.API_URL}/admin/subject`, {
          method: "POST",
          headers: headers,
          body: JSON.stringify(dataToSend),
        });

        const responseData = await response.json();   
             
        if (responseData.code === 400) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            name: "Bu Fənn artıq daxil edilib.",
          }));
        } else if (response.ok) {
          console.log("Student updated successfully:", responseData);
          handleClose();
          window.location.reload();
        } else {
          console.error("Error updating Student:", responseData.message);
        }
      } catch (error) {
        handleUnauthorizedError(error, navigate);
      }
    }
  };


  return (
    <Modal show={show} onHide={handleCloseModal} className="student-modal">
      <Modal.Header closeButton>
        <div className="modal-title">
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M4 9.48513L8.57372 14L16 5"
              stroke="#696974"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          <span>Yeni Fənn</span>
        </div>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId="formInput1">
            <Form.Label>Fənn adı</Form.Label>
            <Form.Control
              type="text"
              name="name"
              maxLength="64"
              value={formData.name}
              onChange={handleChange}
              placeholder="Fənni daxil edin"
              isInvalid={!!errors.name}
              className={errors.name ? "input-error" : ""}
            />
            <Form.Control.Feedback type="invalid">
              {errors.name}
            </Form.Control.Feedback>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button className="modal-btn btn-green" onClick={handleSubmit}>
          Fənni əlavə et
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddDirections;
