import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { fetchAboutUs } from "../functions/apiService";
import * as consts from "../consts/Consts";
import { handleUnauthorizedError } from "../functions/authUtils";

const useAboutUs = () => {
	const navigate = useNavigate();
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		const fetchData = async () => {
			try {
				const data = await fetchAboutUs(navigate);
				consts.setAboutInfo({
					logo_white: data.logo_white,
					logo_black: data.logo_black,
					company_name: data.company_name,
					description: data.description,
				});

				const faviconLink = document.querySelector("link[rel='icon']") || document.createElement("link");
				faviconLink.rel = "icon";
				faviconLink.href = data.logo_white;
				document.head.appendChild(faviconLink);
			} catch (error) {
				handleUnauthorizedError(error, navigate);
			} finally {
				setLoading(false);
			}
		};

		fetchData();
	}, [navigate]);

	return { loading };
};

export default useAboutUs;
