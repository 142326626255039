import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Pagination } from "@mui/material";
import {
  teachingDirectionsTable,
  teachingDirectionsTableHeading,
} from "../../utils/data";
import TeachingDirectionsTableItem from "./TeachingDirectionsTableItem";
import Form from "react-bootstrap/Form";
import FilterSelect from "./FilterSelect";
import AlertModal from "./AlertModal";
import AddDirections from "./AddDirections";
import EditDirections from "./EditDirections";
import { fetchSubjects } from "../../functions/apiService";
import { handleUnauthorizedError } from "../../functions/authUtils";

function TeachingDirectionsTable() {
  const navigate = useNavigate();
  const [openSelectTwo, setOpenSelectTwo] = useState(false);
  const [alertShow, setAlertShow] = useState(false);
  const [direction, setDirection] = useState("");
  const handleClose = () => {
    setAlertShow(false);
    setDirection("");
  };
  const handleOpen = (name) => {
    setDirection(name);
  };

  const [addShow, setAddShow] = useState(false);
  const handleAddClose = () => {
    setAddShow(false);
  };

  const [getData, setGetData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await fetchSubjects(navigate);
        setGetData(data);
      } catch (error) {
        handleUnauthorizedError(error, navigate);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);
  return (
    <>
      {/* <AlertModal
        type="delete"
        show={alertShow}
        handleClose={handleClose}
        alertTitle="Fənnni silmək istədiyinizdən əminsinizmi ?"
        alertContent={`${direction} sistemdən silinəcək !`}
      /> */}
      <AddDirections handleClose={handleAddClose} show={addShow} />

      <div className="groups">
        <div className="groups-header">
          <button className="add-button" onClick={() => setAddShow(true)}>
            <svg
              width="23"
              height="22"
              viewBox="0 0 23 22"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                x="8.5"
                y="8"
                width="13"
                height="13"
                stroke="#141B34"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M16.5 8V1H1.5V16H8.5"
                stroke="#141B34"
                strokeWidth="1.5"
                strokeLinejoin="round"
              />
              <path
                d="M18 14.5H12M15 11.5V17.5"
                stroke="#141B34"
                strokeWidth="1.5"
              />
            </svg>

            <div>Yeni fənn əlavə et</div>
          </button>

          <div className="header-right">
           
          </div>
        </div>
        <div className="groups-body">
          <table>
            <thead>
              <tr>
                {teachingDirectionsTableHeading.map((item, i) => (
                  <th key={item.value}>
                    <div className={`${i === 0 ? "left" : ""} column-content`}>
                      {item.name}
                    </div>
                  </th>
                ))}
                <th>
                  <div className="right column-content">Əməliyyatlar</div>
                </th>
              </tr>
            </thead>

            <tbody>
              {getData &&
                getData.map((item, i) => (
                  <TeachingDirectionsTableItem data={item} key={i} />
                ))}
            </tbody>
          </table>
        </div>
       
      </div>
    </>
  );
}

export default TeachingDirectionsTable;
