import TeachersTable from "../components/admin/TeachersTable";

function Teachers() {
  return (
    <>
      <section>
        <h3 className="title mb-3">Müəllimlər</h3>
        <TeachersTable />
      </section>
    </>
  );
}

export default Teachers;
