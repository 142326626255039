import { useState } from "react";
import * as consts from "../../consts/Consts";
import { createHeaders } from "../../functions/apiService";
import { useNavigate } from "react-router-dom";
import PasswordIcon from "../PasswordIcon";

function PasswordInfoForm({ role }) {
	const [errors, setErrors] = useState({});
	const [formData, setFormData] = useState({
		current_password: "",
		new_password: "",
		new_password_confirmation: "",
	});
	const [showCurrentPassword, setShowCurrentPassword] = useState(false);
	const [showNewPassword, setShowNewPassword] = useState(false);
	const [showNewPasswordConfirmation, setShowNewPasswordConfirmation] =
		useState(false);

	const navigate = useNavigate();

	const handleChange = (e) => {
		const { name, value } = e.target;
		setFormData({ ...formData, [name]: value });
	};

	const changePassword = async (e) => {
		e.preventDefault();
		const validationErrors = validation();
		if (Object.keys(validationErrors).length > 0) {
			setErrors(validationErrors);
			return;
		}

		const headers = createHeaders();
		const apiPath =
			role === "teacher"
				? `${consts.API_URL}/teacher/profile/change-password`
				: role === "admin"
					? `${consts.API_URL}/admin/profile/change-password`
					: `${consts.API_URL}/student/profile/change-password`
		try {
			const response = await fetch(apiPath, {
				method: "POST",
				headers: headers,
				body: JSON.stringify(formData),
			});

			if (response.ok) {
				setFormData({
					current_password: "",
					new_password: "",
					new_password_confirmation: "",
				});
				setErrors({});
				navigate("/");
			} else {
				const errorData = await response.json();
				console.error(
					errorData.message || "Şifrəni dəyişdirmək mümkün olmadı."
				);
			}
		} catch (error) {
			console.error("An error occurred:", error);
		}
	};

	const validation = () => {
		let errors = {};
		let minLengthRegex = /^.{8,}$/;
		let leastUppercase = /(?=.*[A-Z])/;
		let leastLowercase = /(?=.*[a-z])/;
		let leastDigit = /(?=.*\d)/;
		let leastSpecialChar = /(?=.*[@$!%*?&.-])/; 

		if (formData.new_password.length === 0) {
			errors.new_password = "Şifrə boş olmamalıdır.";
		} else {
			let messages = [];
			if (!minLengthRegex.test(formData.new_password)) {
				messages.push("minimum 8 simvoldan");
			}
			if (!leastUppercase.test(formData.new_password)) {
				messages.push("1 böyük hərfdən");
			}
			if (!leastLowercase.test(formData.new_password)) {
				messages.push("1 kiçik hərfdən");
			}
			if (!leastDigit.test(formData.new_password)) {
				messages.push("1 rəqəmdən");
			}
			if (!leastSpecialChar.test(formData.new_password)) {
				messages.push("1 xüsusi simvoldan (@$!%*?&.-)");
			}
			if (messages.length > 0) {
				errors.new_password = `Şifrə ${messages.join(", ")} ibarət olmalıdır.`;
			}
		}
	
		if (formData.new_password_confirmation.length === 0) {
			errors.new_password_confirmation = "Şifrə yenidən daxil olunmalıdır.";
		} else if (formData.new_password_confirmation !== formData.new_password) {
			errors.new_password_confirmation = "Şifrə düzgün deyil.";
		}
	
		if (formData.current_password.length === 0) {
			errors.current_password = "Şifrənizi daxil edin";
		}
	
		return errors;
	};
	
	return (
		<>
			<form
				className="edit-profile-password-form"
				id="infoForm"
				autoComplete="off"
			>
				<div className="pi-form-wrapper">
					<div className="pi-form-inner input-password">
						<label htmlFor="current_password">Köhnə şifrə</label>
						<div className="input-password">
							<input
								type={showCurrentPassword ? "text" : "password"}
								className={`profile-input secondary-input ${errors.current_password ? "border-red" : ""
									}`}
								name="current_password"
								value={formData.current_password}
								onChange={handleChange}
								autoComplete="current_password"

							/>
							<PasswordIcon
								showPassword={showCurrentPassword}
								togglePasswordVisibility={() =>
									setShowCurrentPassword((prev) => !prev)
								}
							/>
						</div>
						<span className="validation-error">{errors.current_password}</span>
					</div>

					<div className="form-row">
						<div className="pi-form-inner input-password">
							<label htmlFor="new_password">Yeni şifrə</label>
							<div className="input-password">
								<input
									type={showNewPassword ? "text" : "password"}
									className={`profile-input secondary-input ${errors.new_password ? "border-red" : ""
										}`}
									name="new_password"
									value={formData.new_password}
									onChange={handleChange}
									autoComplete="new-password"
								/>
								<PasswordIcon
									showPassword={showNewPassword}
									togglePasswordVisibility={() =>
										setShowNewPassword((prev) => !prev)
									}
								/>
							</div>
							<span className="validation-error">{errors.new_password}</span>
						</div>

						<div className="pi-form-inner input-password">
							<label htmlFor="new_password_confirmation">
								Yeni şifrənin təkrarı
							</label>
							<div className="input-password">
								<input
									type={showNewPasswordConfirmation ? "text" : "password"}
									className={`profile-input secondary-input ${errors.new_password_confirmation ? "border-red" : ""
										}`}
									name="new_password_confirmation"
									value={formData.new_password_confirmation}
									onChange={handleChange}
								/>
								<PasswordIcon
									showPassword={showNewPasswordConfirmation}
									togglePasswordVisibility={() =>
										setShowNewPasswordConfirmation((prev) => !prev)
									}
								/>
							</div>
							<span className="validation-error">
								{errors.new_password_confirmation}
							</span>
						</div>
					</div>
				</div>

				<div className="password-btn-container">
					<button
						type="submit"
						className="password-btn outlined-btn"
						onClick={changePassword}
					>
						Şifrəni yenilə
					</button>
				</div>
			</form>
		</>
	);
}

export default PasswordInfoForm;
