import React from "react";
import GroupsTable from "../components/admin/GroupsTable";

function GroupsAdmin() {
  return (
    <section className="group-admin">
      <h3 className="title mb-3">Qruplar</h3>
      <GroupsTable />
    </section>
  );
}

export default GroupsAdmin;
