import React from "react";
import { Link } from "react-router-dom";
import GradeTable from "../components/teachers/GradeTable";
import NotesTable from "../components/teachers/NotesTable";

function Grade({role}) {
  return (
    <>
      <section className="grade">
        <GradeTable role={role}  />
        <NotesTable role={role} />
      </section>
    </>
  );
}

export default Grade;
