import DeletedTable from "../components/admin/DeletedTable";

function Deleted() {
  return (
    <>
      <section>
        <h3 className="title mb-3">Silinmişlər</h3>
        <DeletedTable/>
      </section>
    </>
  );
}

export default Deleted;
