import EditProfile from "../components/admin/EditProfile";

function Profile() {
  return (
    <>
      <section>
        <h1 className="profile-title">Profili redaktə et</h1>
        <EditProfile />
      </section>
    </>
  );
}

export default Profile;
