import EvaluateTable from "../components/admin/EvaluateTable";
import LanguageTable from "../components/admin/LanguageTable";

const Settings = () => {
  return (
    <>
      <section className="settings">
        <h1 className="mb-4 title">Parametrlər</h1>
        <div className="settings-row">
          {/* <div className="table-col">
            <h3 className="title mb-3">Dil</h3>
            <LanguageTable />
          </div> */}

          <div className="table-col">
            <h3 className="title mb-3">Qiymətləndirmə</h3>
            <EvaluateTable />
          </div>
        </div>
      </section>
    </>
  );
};

export default Settings;
