import { useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { createHeaders } from "../../functions/apiService";
import { handleUnauthorizedError } from "../../functions/authUtils";
import { useNavigate } from "react-router-dom";
import * as consts from "../../consts/Consts";

const AddTemplate = ({ show, handleCloseModal }) => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    note: "",
  });
  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    if (value.trim() && errors[name]) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: undefined,
      }));
    }
  };

  const validate = () => {
    let errors = {};
    if (!formData.note.trim()) {
      errors.note = "Qeyd tələb olunur!";
    }

    setErrors(errors);

    return errors;
  };

  const resetData = () => {
    setFormData({
      note: "",
    });

    setErrors({
      note: "",
    });
  };

  const handleSubmit = async () => {
    if (validate()) {
      const headers = createHeaders();

      const dataToSend = {
        translations: [
          {
            language_id: 1,
            note: formData.note,
          },
        ],
      };

      try {
        const response = await fetch(`${consts.API_URL}/admin/note-template`, {
          method: "POST",
          headers: headers,
          body: JSON.stringify(dataToSend),
        });

        const responseData = await response.json();   
             
       if(responseData.code=200) {
        handleCloseModal();
        window.location.reload();
       }
      } catch (error) {
        handleUnauthorizedError(error, navigate);
      }
    }
  };

  return (
    <>
      <Modal show={show} onHide={handleCloseModal} className="student-modal">
        <Modal.Header closeButton>
          <div className="modal-title">
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M4 9.48513L8.57372 14L16 5"
                stroke="#696974"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            <span>Yeni şablon</span>
          </div>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formInput1">
              <Form.Label>Şablon adı</Form.Label>
              <Form.Control
                type="text"
                as="textarea"
                name="note"
                value={formData.note}
                onChange={handleChange}
                placeholder="Şablon daxil edin"
                isInvalid={!!errors.note}
                className={errors.note ? "input-error" : ""}
                style={{ height: "150px" }}
              />
              <Form.Control.Feedback type="invalid">
                {errors.note}
              </Form.Control.Feedback>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button className="modal-btn btn-green" onClick={handleSubmit}>
            Şablonu əlavə et
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AddTemplate;
