import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";

function Footer() {
	const [activeLanguage, setActiveLanguage] = useState("English");

	const handleLanguageClick = (language) => {
		setActiveLanguage(language);
	};

	return (
		<footer className="footer p-4">
			<Row className="align-items-center">
				<Col xs={12} md={6} className="text-start text-md-left">
					<div className="footer__copyright">
						Copyright © 2024 — Developed by “Performance System”
					</div>
				</Col>
				{/* <Col xs={12} md={6} className="text-end text-md-right">
          <ul className="footer__language list-inline">
            {["Azərbaycanca", "English", "Русский"].map((language) => (
              <li
                key={language}
                className={`list-inline-item ${
                  activeLanguage === language ? "active" : ""
                }`}
                onClick={() => handleLanguageClick(language)}
                style={{ cursor: "pointer" }}
              >
                {language}
              </li>
            ))}
          </ul>
        </Col> */}
			</Row>
		</footer>
	);
}

export default Footer;
