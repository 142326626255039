import React, { useState, useRef, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Form from "react-bootstrap/Form";
import AddGradeTableItem from "./AddGradeTableItem";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import * as consts from "../../consts/Consts";
import { handleUnauthorizedError } from "../../functions/authUtils";
import { createHeaders } from "../../functions/apiService";
import SucessModal from "../SucessModal";

function AddGradeTable({ role }) {
	const navigate = useNavigate();
	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);
	const groupId = queryParams.get("group");
	const { getData } = location.state || {};
	const { selectedGroup } = location.state || {};
	const { groups } = location.state || {};
	const [openSelectSubject, setOpenSelectSubject] = useState(false);
	const [selectedDate, setSelectedDate] = useState(new Date());
	const [showCalendar, setShowCalendar] = useState(false);
	const calendarRef = useRef(null);
	const [updatedStudentsData, setUpdatedStudentsData] = useState([]);

	const [selectedSubject, setSelectedSubject] = useState("daily");

	const [errors, setErrors] = useState({});

	const [showSuccessModal, setShowSuccessModal] = useState(false);

	const handleDateChange = (date) => {
		setSelectedDate(date);
		setShowCalendar(false);
	};

	const handleClickOutside = (event) => {
		if (calendarRef.current && !calendarRef.current.contains(event.target)) {
			setShowCalendar(false);
		}
	};

	useEffect(() => {
		if (showCalendar) {
			document.addEventListener("mousedown", handleClickOutside);
		} else {
			document.removeEventListener("mousedown", handleClickOutside);
		}
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, [showCalendar]);

	const groupName =
		groups?.find((group) => group.id === parseInt(selectedGroup))?.name ||
		"Qrup tapılmadı";

	const handleSubjectChange = (event) => {
		setSelectedSubject(event.target.value);
		setOpenSelectSubject(false);
	};

	const validateForm = () => {
		let formErrors = {};

		const hasEmptyAttendance = updatedStudentsData.some(
			(student) => student.attendance === null
		);

		if (hasEmptyAttendance) {
			formErrors.students = "Hər bir tələbə üçün davamiyyəti doldurun.";
		}

		const hasEmptyGrade = updatedStudentsData.some(
			(student) =>
				student.attendance !== 0 &&
				student.attendance !== 1 &&
				(student.grade === null || student.grade === "")
		);

		if (hasEmptyGrade) {
			formErrors.grade = "İştirak edən tələbələr üçün qiymət daxil edin.";
		}

		const currentDate = new Date();
		const selectedDateWithoutTime = new Date(selectedDate.toDateString());
		const currentDateWithoutTime = new Date(currentDate.toDateString());

		if (
			selectedSubject === "daily" &&
			selectedDateWithoutTime.getTime() !== currentDateWithoutTime.getTime()
		) {
			formErrors.date = '"Günlük" formu yalnız cari tarix üçün doldurmaq olar.';
		}

		setErrors(formErrors);

		return Object.keys(formErrors).length === 0;
	};

	const handleSubmit = async (e) => {
		e.preventDefault();

		if (!validateForm()) {
			return;
		}

		const studentsData = updatedStudentsData.map((student) => ({
			student_id: student.student_id,
			attendance: student.attendance,
			grade: student.grade || null,
			note: student.note || null,
		}));

		const formattedDate = selectedDate.toLocaleDateString("ru-RU", {
			day: "2-digit",
			month: "2-digit",
			year: "numeric",
		});

		const requestData = {
			students: studentsData,
			group_id: parseInt(selectedGroup),
			date: formattedDate,
			status: selectedSubject,
		};

		const headers = createHeaders();

		try {
			const response = await fetch(`${consts.API_URL}/teacher/grade`, {
				method: "POST",
				headers: headers,
				body: JSON.stringify(requestData),
			});

			if (!response.ok) {
				const errorData = await response.json();

				if (
					errorData.code === 400 &&
					errorData.message === "Validation error."
				) {
					// const alreadyExistsError = Object.values(errorData.errors)
					// 	.flat()
					// 	.some((errMsg) => errMsg.includes("This grade already exists"));

					const alreadyExistsError = Object.values(errorData.errors)
						.flat()
						.some((errMsg) => errMsg.includes("A grade with this status already exists"));

					const absentError = Object.values(errorData.errors)
						.flat()
						.some((errMsg) =>
							errMsg.includes("The student was absent on this date")
						);

					const lessonExists = Object.values(errorData.errors)
						.flat()
						.some((errMsg) =>
							errMsg.includes("The grade can only be set for the day when there is a lesson.")
						);

					if (alreadyExistsError || absentError) {
						setErrors({ form: "Bu günə forma artıq doldurulub." });
						return;
					}
					else if (lessonExists) {
						setErrors({ form: "Dərs olmayan günə qiymət daxil edilə bilməz." });
						return;
					}
					else if (absentError) {
						setErrors({ form: "Tələbə bu gün iştirak etməyib" });
						return;
					}
				}

				throw new Error("Ошибка сети");
			}

			const data = await response.json();
			// console.log("Данные успешно отправлены:", data);
			setShowSuccessModal(true);
		} catch (error) {
			// console.error("Ошибка при отправке запроса:", errors);
			handleUnauthorizedError(error);
		}
	};

	const handleUpdateStudent = (updatedStudent) => {
		setUpdatedStudentsData((prevData) => {
			const studentIndex = prevData.findIndex(
				(student) => student.student_id === updatedStudent.student_id
			);
			if (studentIndex > -1) {
				const updatedData = [...prevData];
				updatedData[studentIndex] = updatedStudent;
				return updatedData;
			} else {
				return [...prevData, updatedStudent];
			}
		});
	};

	const handleGoBack = () => {
		if (window.innerWidth <= 768) {
			navigate("/teacher");
		} else {
			navigate("/grade");
		}
	};

	return (
		<>
			<SucessModal
				show={showSuccessModal}
				handleClose={() => setShowSuccessModal(false)}
				role={role}
			/>
			<form action="">
				<div className="groups">
					<div className="groups-header groups-header-grade">
						<div className="header-left">
							<h3 className="title mb-3">{groupName}</h3>
						</div>
						<div className="header-right">
							<p
								className="day-navigation"
								style={{ position: "relative" }}
								onClick={() => setShowCalendar((prev) => !prev)}
							>
								<span>{selectedDate.toLocaleDateString()}</span>
								<svg
									width="17"
									height="18"
									viewBox="0 0 17 18"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M12.8543 0.583374V3.75004M4.146 0.583374V3.75004"
										stroke="#707070"
										strokeWidth="1.5"
										strokeLinejoin="round"
									/>
									<path
										d="M15.625 2.16675H1.375V16.4167H15.625V2.16675Z"
										stroke="#707070"
										strokeWidth="1.5"
										strokeLinejoin="round"
									/>
									<path
										d="M7.7168 9.6875H11.6751M5.3418 9.6875H5.34891M9.30013 12.8542H5.3418M11.6751 12.8542H11.668"
										stroke="#707070"
										strokeWidth="1.5"
										strokeLinecap="square"
										strokeLinejoin="round"
									/>
									<path
										d="M1.375 6.125H15.625"
										stroke="#707070"
										strokeWidth="1.5"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
								</svg>
							</p>
							<div className="group-subject d-flex align-items-center">
								<Form.Select
									className={`selection ${openSelectSubject ? "active" : ""}`}
									onClick={() => setOpenSelectSubject((prev) => !prev)}
									value={selectedSubject}
									onChange={handleSubjectChange}
								>
									<option value="daily">Günlük</option>
									<option value="exam">İmtahan</option>
									<option value="quiz">Quiz</option>
									<option value="project">Disiplin</option>
								</Form.Select>
							</div>
						</div>
					</div>
					<div className="groups-body">
						<table>
							<thead>
								<tr>
									<th>
										<div
											className="add-grade left column-content"
										// style={{ width: "500px" }}
										>
											Tələbə
										</div>
									</th>
									<th>
										<div className="column-content">Qiymət</div>
									</th>
									<th>
										<div className="column-content">Plus</div>
									</th>
									<th>
										<div className="column-content">Qayıb</div>
									</th>
									<th>
										<div className="right column-content">Qeyd</div>
									</th>
								</tr>
							</thead>
							<tbody>
								{getData &&
									getData.data &&
									getData.data.students &&
									getData.data.students
										.sort((a, b) => (a.full_name || "").localeCompare(b.full_name || ""))
										.map((student, i) => (
											<AddGradeTableItem
												data={student}
												key={i}
												role={role}
												onUpdate={handleUpdateStudent}
											/>
										))}
							</tbody>

						</table>
					</div>
				</div>
				{Object.keys(errors).map((key) => (
					<div className="error mt-3" key={key}>
						{errors[key]}
					</div>
				))}
				<div className="grade-btns mb-5">
					{/* <Link to="/grade" className="btn-grey button">
						<span>Geriyə</span>
					</Link> */}
					<button
						className="btn-grey button"
						type="button"
						onClick={handleGoBack}
					>
						Geriyə
					</button>
					<button
						type="submit"
						className="btn-add button"
						onClick={handleSubmit}
					>
						Yadda saxla
					</button>
				</div>
			</form>
			{showCalendar && (
				<div className="calendar-popup">
					<DatePicker
						selected={selectedDate}
						onChange={handleDateChange}
						inline
					/>
				</div>
			)}
		</>
	);
}

export default AddGradeTable;
