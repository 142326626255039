import React, { useState } from "react";
import { Col } from "react-bootstrap";
import DeleteMessage from "./DeleteMessage";
import UserProfile from "../UserProfile";

function RequestMessage({ selectedRequestData }) {
  const fullName = selectedRequestData.teacher
    ? selectedRequestData.teacher.full_name
    : selectedRequestData.student
      ? selectedRequestData.student.full_name
      : "Unknown";

  const role = selectedRequestData.teacher
    ? selectedRequestData.teacher.role
    : selectedRequestData.student
      ? selectedRequestData.student.role
      : "Unknown";

  const email = selectedRequestData.teacher
    ? selectedRequestData.teacher.email
    : selectedRequestData.student
      ? selectedRequestData.student.email
      : "Unknown";

  const image = selectedRequestData.teacher
    ? selectedRequestData.teacher.image
    : selectedRequestData.student
      ? selectedRequestData.student.image
      : "Unknown";

  const [modalShowDelete, setModalShowDelete] = useState(false);

  const handleModalCloseDelete = () => {
    setModalShowDelete(false);
  };

  const handleModalOpenDelete = () => {
    setModalShowDelete(true);
  };
  return (
    <>
      <DeleteMessage
        show={modalShowDelete}
        handleClose={handleModalCloseDelete}
        data={selectedRequestData}
      />
      <Col xs={12} md={8}>
        <div className="request__right" style={{ height: "100%" }}>
          <div className="request__top">
            <div className="request__top-left">
              {image ? (
                <img src={image} alt="Profile" style={{width: "60px", height: "60px"}} />
              ) : (
                <div>
                  <UserProfile imageSize={45} containerSize={60} />
                </div>
              )}
              <div className="request-top__body">
                <h5 className="request-top__name">{fullName}</h5>
                <div className="request-top__info">
                  <p className="request-top__role">
                    {role === "teacher"
                      ? "Müəllim"
                      : role === "student"
                        ? "Tələbə"
                        : ""}
                  </p>
                  <p className="request-top__group">{email}</p>
                </div>
              </div>
            </div>
            <button
              className="request-top__delete"
              onClick={handleModalOpenDelete}
            >
              <svg
                width="20"
                height="20"
                viewBox="0 0 35 48"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M0.731637 15.4725C0.104727 11.1737 3.43777 7.31934 7.78206 7.31934H27.3291C31.6734 7.31934 35.0064 11.1737 34.3795 15.4725L30.6526 41.0281C30.1421 44.5289 27.1401 47.1249 23.6022 47.1249H11.5089C7.97104 47.1249 4.96903 44.5289 4.45849 41.0281L0.731637 15.4725ZM7.78206 11.5693C6.02911 11.5693 4.68419 13.1246 4.93715 14.8592L8.664 40.4148C8.87001 41.8274 10.0813 42.8749 11.5089 42.8749H23.6022C25.0298 42.8749 26.2411 41.8274 26.4471 40.4148L30.174 14.8592C30.4269 13.1246 29.082 11.5693 27.3291 11.5693H7.78206Z"
                  fill="#1A1D1F"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M10.9861 2.5C10.9861 1.32639 11.9375 0.375 13.1111 0.375H22C23.1736 0.375 24.125 1.32639 24.125 2.5C24.125 3.67361 23.1736 4.625 22 4.625H13.1111C11.9375 4.625 10.9861 3.67361 10.9861 2.5Z"
                  fill="#1A1D1F"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M10.6768 21.2929C11.4969 21.1758 12.2567 21.7456 12.3738 22.5657L14.596 38.1213C14.7132 38.9414 14.1433 39.7012 13.3232 39.8183C12.5031 39.9355 11.7433 39.3657 11.6262 38.5456L9.40396 22.99C9.28681 22.1699 9.85666 21.4101 10.6768 21.2929Z"
                  fill="#1A1D1F"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M21.7879 39.8183C20.9678 39.7012 20.3979 38.9414 20.5151 38.1213L22.7373 22.5657C22.8545 21.7456 23.6143 21.1758 24.4344 21.2929C25.2545 21.4101 25.8243 22.1699 25.7071 22.99L23.4849 38.5455C23.3678 39.3656 22.608 39.9355 21.7879 39.8183Z"
                  fill="#1A1D1F"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M17.5556 39.8333C16.7271 39.8333 16.0556 39.1617 16.0556 38.3333L16.0556 22.7777C16.0556 21.9493 16.7271 21.2777 17.5556 21.2777C18.384 21.2777 19.0556 21.9493 19.0556 22.7777L19.0556 38.3333C19.0556 39.1617 18.384 39.8333 17.5556 39.8333Z"
                  fill="#1A1D1F"
                />
              </svg>
            </button>
          </div>
          <div className="request__bottom">
            <Col xs={12} className="mb-4">
              <div className="request__message d-flex">
                {/* <div className="request__message-img">
                  <img src="/img/avatar_requests.png" alt="" />
                </div> */}
                <div className="request-message__body d-flex flex-column">
                  <div className="request-message__info d-flex">
                    <p className="request-message__name">
                      {selectedRequestData.theme}
                    </p>
                    <p className="request-message__date">{selectedRequestData.date}</p>
                  </div>
                  <div className="request-message__message">
                    {selectedRequestData.message}
                  </div>
                </div>
              </div>
            </Col>
          </div>
        </div>
      </Col>
    </>
  );
}

export default RequestMessage;
