import { SET_SELECTED_GROUP, SET_DATA_STATUS, SET_GET_DATA, SET_GROUP_NAME } from './action';

const initialState = {
	selectedGroup: null,
	dataStatus: 0,
	getData: 0,
	groupName: '',
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case SET_SELECTED_GROUP:
			return {
				...state,
				selectedGroup: action.payload,
			};
		case SET_DATA_STATUS:
			return {
				...state,
				dataStatus: action.payload,
			};
		case SET_GET_DATA: // Handle the new action
			return {
				...state,
				getData: action.payload,
			};
		case SET_GROUP_NAME:
			return {
				...state,
				groupName: action.payload,
			};
		default:
			return state;
	}
};

export default reducer;
