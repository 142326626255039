import { NavLink } from "react-router-dom";
import { exercisesNavbar } from "../../utils/data";

const ExercisesNavbar = ({ role }) => {
  
  const filteredNavbar = exercisesNavbar.filter(
    (item) =>
      role === "teacher" || (item.path === "/exercises/current" || item.path === "/exercises/past")
  );

  return (
    <>
      <nav>
        <ul className="exercises-navbar">
          {filteredNavbar.map((item) => (
            <li key={item.path}>
              <NavLink
                to={item.path}
                className={({ isActive }) => (isActive ? "active" : "")}
              >
                {item.name}
              </NavLink>
            </li>
          ))}
        </ul>
      </nav>
    </>
  );
};

export default ExercisesNavbar;
