import { useEffect, useState } from 'react';
import ExerciseCard from "../components/teachers/ExerciseCard";
import { fetchCurrentTask, fetchStudentCurrentTask } from '../functions/apiService';
import { useNavigate } from 'react-router-dom';
import { handleUnauthorizedError } from '../functions/authUtils';

const CurrentExercises = ({ role }) => {

	const navigate = useNavigate();
	const [getData, setGetData] = useState([]);
	const [loading, setLoading] = useState(true);


	useEffect(() => {
		const fetchData = async () => {
			try {
				const data = role === "student"
					? await fetchStudentCurrentTask(navigate)
					: await fetchCurrentTask(navigate);
				setGetData(data);
			} catch (error) {
				handleUnauthorizedError(error, navigate);
			} finally {
				setLoading(false);
			}
		};

		fetchData();
	}, [role, navigate]);

	if (!getData) {
		return null;
	}

	return (
		<>
			<div className="exercise-cards">
				{getData && getData.map((item) => (
					<ExerciseCard data={item} key={item.id} role={role} />
				))}
			</div>
		</>
	);
};

export default CurrentExercises;
