import { Widgets } from '@mui/icons-material';
import React from 'react';
import Select from 'react-select';

const customStyles = (isInvalid) => ({
	control: () => ({
		height: '49px',
		border: isInvalid ? '1px solid #dc3545' : '2px solid #efefef',
		background: '#fcfcfc',
		borderRadius: '10px',
		fontSize: '14px',
		boxShadow: 'none',
		display: 'flex',
		alignItems: 'center',
	}),
	menu: (provided) => ({
		...provided,
		// maxHeight: 200,
		overflowY: 'auto',
	}),
	input: (provided) => ({
		...provided,
		height: '49px',
		margin: 0,
		padding: '0',
		opacity: '0'
	}),
	singleValue: (provided) => ({
		...provided,
		lineHeight: '49px',
		whiteSpace: 'normal', // Allow text to wrap
		wordBreak: 'break-word', // Break long words
		textOverflow: 'ellipsis', // Add ellipsis for overflow text
		overflow: 'hidden',
	}),
	valueContainer: (provided) => ({
		...provided,
		height: '49px',
		padding: '0 8px',
		display: 'flex',
		alignItems: 'center',
	}),
	indicatorsContainer: (provided) => ({
		...provided,
		height: '49px',
		display: 'flex',
		alignItems: 'center',
	}),
	indicatorSeparator: () => ({
		display: 'none',
	}),
	dropdownIndicator: (provided) => ({
		...provided,
		padding: '0',
		height: '49px',
		display: 'flex',
		alignItems: 'center',
		marginRight: '10px',
	}),
	clearIndicator: (provided) => ({
		...provided,
		padding: '0',
	}),
	option: (provided, state) => ({
		...provided,
		backgroundColor: state.isSelected ? '#1ae5be' : (state.isFocused ? '#e0e0e0' : 'white'),
		color: '#000',
		padding: '10px',
		// whiteSpace: 'normal', 
		// wordBreak: 'break-word',
	}),
});

const CustomSelect = ({ options, value, onChange, placeholder, isInvalid }) => {
	return (
		<Select
			options={options}
			value={value}
			onChange={onChange}
			placeholder={placeholder}
			styles={customStyles(isInvalid)}
			isInvalid={isInvalid}
		/>
	);
};

export default CustomSelect;
