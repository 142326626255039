import CryptoJS from "crypto-js";
import { refreshAccessToken } from './authService'; // Импортируем функцию обновления токена
import { clearTokens, getAccessTokenFromCookie, getRefreshTokenFromCookie } from './tokenService'; // Работа с токенами

// Функция для генерации случайного nonce длиной 8 символов
export function generateRandomNonce(length) {
	const charset =
		"ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
	let nonce = "";
	for (let i = 0; i < length; i++) {
		const randomIndex = Math.floor(Math.random() * charset.length);
		nonce += charset[randomIndex];
	}
	return nonce;
}

// Функция для создания MD5 подписи
export function generateSignature(datetime, nonce, salt, epochDivided) {
	const stringForHash = `${datetime}${nonce}${salt}${epochDivided}`;
	return CryptoJS.MD5(stringForHash).toString();
}

// Функция для получения текущего времени в формате ISO
export function getCurrentDatetime() {
	return new Date().toISOString().slice(0, 19).replace("T", " ");
}

// Функция для получения текущего времени в миллисекундах
export function getCurrentEpochDivided() {
	const currentEpoch = Date.now();
	return Math.floor(currentEpoch / 300000);
}

export const handleUnauthorizedError = async (response, navigate) => {
	if (!navigate || typeof navigate !== 'function') {
		// console.error("navigate не передан или не является функцией.");
		return;
	}

	if (response.status === 401) {
		try {
			console.warn("Неавторизован: попытка обновить токен.");

			// Попробуем обновить токен
			const refreshToken = getRefreshTokenFromCookie();
			if (refreshToken) {
				const newAccessToken = await refreshAccessToken(refreshToken); // Попытка обновления токена
				if (newAccessToken) {
					// console.log("Токен успешно обновлен");
					return; // Возвращаемся без перенаправления
				}
			}

			// Если обновление токена не удалось, удаляем токены и перенаправляем на логин
			throw new Error("Токен не удалось обновить");
		} catch (error) {
			// console.error("Не удалось обновить токен:", error);
			clearTokens(); // Очищаем токены
			navigate("/"); // Перенаправляем на логин
		}
	} else {
		// console.error("Произошла ошибка:", response.statusText || response);
	}
};



// export const handleUnauthorizedError = (response, navigate) => {
//   if (response.status === 401) {
//     console.error("Неавторизован: Перенаправление на страницу логина");

//     localStorage.removeItem("token");
//     localStorage.removeItem("role");

//     navigate("/");
//   } else {
//     console.error("Произошла ошибка:", response.statusText || response);
//   }
// };
