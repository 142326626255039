import { Form, Modal } from "react-bootstrap";
import { createHeaders } from "../../functions/apiService";
import { useEffect, useState } from "react";
import { handleUnauthorizedError } from "../../functions/authUtils";
import { useNavigate } from "react-router-dom";
import * as consts from "../../consts/Consts";


const TemplateInfo = ({ show, handleCloseModal, data }) => {
  console.log(data);
  
  // const [formData, setFormData] = useState({
  //   notes: "",
  // });
  // const [loading, setLoading] = useState(true);
  // const navigate = useNavigate()

  // const handleGetData = async () => {
  //   try {
  //     const headers = createHeaders();
  //     const response = await fetch(
  //       `${consts.API_URL}/admin/note-template/${data.id}`,
  //       {
  //         headers,
  //       }
  //     );
  //     if (!response.ok) {
  //       throw new Error("Ошибка при получении данных");
  //     }
  //     const result = await response.json();
  //     if (result.data && typeof result.data === "object") {
  //       const translation = result.data.translations.find(
  //         (t) => t.language.code === "az"
  //       );
  //       const notes = translation?.notes || "";
  //       setFormData({
  //         notes,
  //       });
  //     } else {
  //       console.error("Ожидался объект, но получен:", result.data);
  //     }
  //   } catch (error) {
  //     handleUnauthorizedError(error, navigate);
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  // useEffect(() => {
  //   if (show && data?.id) {
  //     handleGetData();
  //   }
  // }, [show, data?.id]);


  return (
    <>
      <Modal show={show} onHide={handleCloseModal} className="student-modal">
        <Modal.Header closeButton>
          <div className="modal-title">
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M4 9.48513L8.57372 14L16 5"
                stroke="#696974"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            <span>Şablon</span>
          </div>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formInput1">
              <Form.Label>Şablon adı</Form.Label>
              <Form.Control
                type="text"
                as="textarea"
                name="text"
                value={data.translations[0].note}
                readOnly
                style={{ height: "150px" }}
              />

            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    </>
  );
};

export default TemplateInfo;
