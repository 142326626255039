import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import ProfileMenuDropdown from "./ProfileMenuDropdown";
import AddRequestModal from "../teachers/AddRequestModal";
import * as consts from "../../consts/Consts";
import useAboutUs from "../../hooks/useAboutUs";

const Header = ({ setRole, role }) => {

	const [profileMenu, setProfileMenu] = useState(false);
	const handleToggleProfileMenu = () => setProfileMenu(!profileMenu);

	const [showModal, setShowModal] = useState(false);
	const handleShowModal = () => setShowModal(false);

	const { loading } = useAboutUs();


	return (
		<>
			<AddRequestModal
				showModal={showModal}
				handleShowModal={handleShowModal}
				role={role}
			/>
			<header className="d-flex justify-content-between align-items-center p-3 bg-light header">
				<Link
					to={role === 'admin' ? '/admin' : role === 'teacher' ? '/teacher' : '/student'}
					className="header__logo">
					<img
						// src={LOGO_PATH_NAVBAR}
						src={consts.getAboutInfo().logo_white}
						alt=""
						className={`${consts.getAboutInfo().company_name === "Performance"
							? ""
							: "logo-img"
							}`}
					/>
				</Link>

				<div className="header-actions d-flex align-items-center gap-4">
					{role === "admin" && (
						<Link to="/requests" className="header-message">
							<svg
								width="24"
								height="25"
								viewBox="0 0 24 25"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									fillRule="evenodd"
									clipRule="evenodd"
									d="M7 9.5C7 8.94772 7.44772 8.5 8 8.5H16C16.5523 8.5 17 8.94772 17 9.5C17 10.0523 16.5523 10.5 16 10.5H8C7.44772 10.5 7 10.0523 7 9.5Z"
									fill="white"
								/>
								<path
									fillRule="evenodd"
									clipRule="evenodd"
									d="M7 13.5C7 12.9477 7.44772 12.5 8 12.5H12C12.5523 12.5 13 12.9477 13 13.5C13 14.0523 12.5523 14.5 12 14.5H8C7.44772 14.5 7 14.0523 7 13.5Z"
									fill="white"
								/>
								<path
									fillRule="evenodd"
									clipRule="evenodd"
									d="M6.71963 17.9636C7.07906 17.664 7.53213 17.5 8 17.5H19C19.5523 17.5 20 17.0523 20 16.5V6.5C20 5.94771 19.5523 5.5 19 5.5H5C4.44772 5.5 4 5.94772 4 6.5V20.2299L6.71963 17.9636ZM8 19.5H19C20.6569 19.5 22 18.1569 22 16.5V6.5C22 4.84315 20.6569 3.5 19 3.5H5C3.34315 3.5 2 4.84315 2 6.5V20.2299C2 21.9256 3.97771 22.8519 5.28037 21.7664L8 19.5Z"
									fill="white"
								/>
							</svg>
							<p className="header-message-count"></p>
						</Link>
					)}

					{/* <Link to="/admin" className="header-notification">
            <svg
              width="20"
              height="21"
              viewBox="0 0 20 21"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M0 15.0959C2.07459e-06 14.7151 0.154705 14.3506 0.428635 14.086L1.45759 13.0922C1.84928 12.7139 2.06977 12.1922 2.06814 11.6476L2.05867 8.4946C2.04543 4.08319 5.61789 0.5 10.0293 0.5C14.4314 0.5 18 4.06859 18 8.47067L18 11.6716C18 12.202 18.2107 12.7107 18.5858 13.0858L19.5858 14.0858C19.851 14.351 20 14.7107 20 15.0858C20 15.8668 19.3668 16.5 18.5858 16.5H14C14 18.7091 12.2091 20.5 10 20.5C7.79086 20.5 6 18.7091 6 16.5H1.40408C0.628628 16.5 0 15.8714 0 15.0959ZM8 16.5C8 17.6046 8.89543 18.5 10 18.5C11.1046 18.5 12 17.6046 12 16.5H8ZM16 11.6716C16 12.7324 16.4214 13.7499 17.1716 14.5L2.87851 14.5C3.64222 13.746 4.07136 12.7161 4.06813 11.6416L4.05867 8.4886C4.04875 5.1841 6.7248 2.5 10.0293 2.5C13.3268 2.5 16 5.17316 16 8.47067L16 11.6716Z"
                fill="white"
              />
            </svg>
            <p className="header-notification-count"></p>
          </Link> */}
					<ProfileMenuDropdown
						show={profileMenu}
						onToggle={handleToggleProfileMenu}
						setRole={setRole}
						role={role}
						setShowModal={setShowModal}
					/>


				</div>
			</header>
		</>
	);
};

export default Header;
