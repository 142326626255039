import { Dropdown, OverlayTrigger, Tooltip } from "react-bootstrap";
import CreateExercise from "./CreateExercise";
import { useState } from "react";
import EditExerciseModal from "./EditExerciseModal";
import DeleteTask from "./DeleteTask";

const truncateContent = (content, maxLength) => {
	if (content.length > maxLength) {
		return content.slice(0, maxLength) + "...";
	}
	return content;
};

const ExerciseCard = ({ data, role }) => {
	const [modalStatus, setModalStatus] = useState({
		exercise: false,
		edit: false,
		delete: false,
	});

	const handleModalToggle = (modalType, status) => {
		setModalStatus((prevState) => ({
			...prevState,
			[modalType]: status,
		}));
	};

	const truncatedContent = truncateContent(data.content, 80);

	const stopDropdownClick = (e) => {
		e.stopPropagation();
	};

	return (
		<>
			<CreateExercise
				show={modalStatus.exercise}
				handleClose={() => handleModalToggle("exercise", false)}
				id={data.id}
				data={data}
			/>

			<EditExerciseModal
				show={modalStatus.edit}
				handleClose={() => handleModalToggle("edit", false)}
				id={data.id}
			/>

			<DeleteTask
				show={modalStatus.delete}
				handleClose={() => handleModalToggle("delete", false)}
				data={data}
			/>

			<div className="exercise-card" key={data.id}
				onClick={() => handleModalToggle("exercise", true)}
			>
				<div className="ec-header">
					<span>
						{data.start_date} - {data.end_date}
					</span>
					<Dropdown onClick={stopDropdownClick}>
						<Dropdown.Toggle variant="white" id="dropdown-basic">
							<svg
								width="20"
								height="20"
								viewBox="0 0 20 20"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M8.3335 15.8333C8.3335 16.75 9.0835 17.5 10.0002 17.5C10.9168 17.5 11.6668 16.75 11.6668 15.8333C11.6668 14.9167 10.9168 14.1667 10.0002 14.1667C9.0835 14.1667 8.3335 14.9167 8.3335 15.8333Z"
									fill="#BDBDBD"
									stroke="#BDBDBD"
								/>
								<path
									d="M8.3335 4.16659C8.3335 5.08325 9.0835 5.83325 10.0002 5.83325C10.9168 5.83325 11.6668 5.08325 11.6668 4.16659C11.6668 3.24992 10.9168 2.49992 10.0002 2.49992C9.0835 2.49992 8.3335 3.24992 8.3335 4.16659Z"
									fill="#BDBDBD"
									stroke="#BDBDBD"
								/>
								<path
									d="M8.3335 10.0001C8.3335 10.9167 9.0835 11.6667 10.0002 11.6667C10.9168 11.6667 11.6668 10.9167 11.6668 10.0001C11.6668 9.08341 10.9168 8.33341 10.0002 8.33341C9.0835 8.33341 8.3335 9.08341 8.3335 10.0001Z"
									fill="#BDBDBD"
									stroke="#BDBDBD"
								/>
							</svg>
						</Dropdown.Toggle>

						<Dropdown.Menu
							style={{
								padding: "16px",
								width: "280px",
								boxShadow: "0px 32px 48px -8px #0000001A",
								border: "none",
								borderRadius: "16px",
							}}
						>
							{role === 'teacher' && (
								<Dropdown.Item
									style={{ padding: "0px", backgroundColor: "transparent" }}
									onClick={() => handleModalToggle("edit", true)}
								>
									<div className="di-wrapper">
										<svg
											width="18"
											height="18"
											viewBox="0 0 18 18"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path
												d="M2.9893 15.8934L6.96677 15.0979C7.11197 15.0689 7.24532 14.9975 7.35002 14.8928L12.3374 9.90543C12.6303 9.61254 12.6303 9.13766 12.3374 8.84477L9.15539 5.66279C8.86249 5.3699 8.38761 5.3699 8.09472 5.66279L3.10738 10.6502C3.00268 10.7549 2.93131 10.8882 2.90227 11.0334L2.10678 15.0109C2.00182 15.5357 2.46451 15.9984 2.9893 15.8934Z"
												fill="currentColor"
											/>
											<path
												d="M15.1444 3.91645L14.0837 2.85579C13.2051 1.97711 11.7804 1.97711 10.9018 2.85579L10.1628 3.59475C9.86991 3.88764 9.86991 4.36252 10.1628 4.65541L13.3448 7.83739C13.6377 8.13029 14.1125 8.13028 14.4054 7.83739L15.1444 7.09843C16.0231 6.21975 16.0231 4.79513 15.1444 3.91645Z"
												fill="currentColor"
											/>
										</svg>
										<span className="dropdown-item-text">Redaktə et</span>
									</div>
								</Dropdown.Item>
							)}
							<Dropdown.Item
								style={{ padding: "0px", backgroundColor: "transparent" }}
								onClick={() => handleModalToggle("exercise", true)}
							>
								<div className="di-wrapper">
									<svg
										width="24"
										height="24"
										viewBox="0 0 24 24"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											fillRule="evenodd"
											clipRule="evenodd"
											d="M2 12.01C2 9.24859 4.23858 7.01001 7 7.01001H10C10.5523 7.01001 11 7.45772 11 8.01001C11 8.56229 10.5523 9.01001 10 9.01001H7C5.34315 9.01001 4 10.3532 4 12.01C4 13.6669 5.34315 15.01 7 15.01H10C10.5523 15.01 11 15.4577 11 16.01C11 16.5623 10.5523 17.01 10 17.01H7C4.23858 17.01 2 14.7714 2 12.01Z"
											fill="currentColor"
										/>
										<path
											fillRule="evenodd"
											clipRule="evenodd"
											d="M13 8.01001C13 7.45772 13.4477 7.01001 14 7.01001H17C19.7614 7.01001 22 9.24859 22 12.01C22 14.7714 19.7614 17.01 17 17.01H14C13.4477 17.01 13 16.5623 13 16.01C13 15.4577 13.4477 15.01 14 15.01H17C18.6569 15.01 20 13.6669 20 12.01C20 10.3532 18.6569 9.01001 17 9.01001H14C13.4477 9.01001 13 8.56229 13 8.01001Z"
											fill="currentColor"
										/>
										<path
											fillRule="evenodd"
											clipRule="evenodd"
											d="M8 12.01C8 11.4577 8.44772 11.01 9 11.01H15C15.5523 11.01 16 11.4577 16 12.01C16 12.5623 15.5523 13.01 15 13.01H9C8.44772 13.01 8 12.5623 8 12.01Z"
											fill="currentColor"
										/>
									</svg>
									<span className="dropdown-item-text">Bax</span>
								</div>
							</Dropdown.Item>
							{role === 'teacher' && (
								<Dropdown.Item
									style={{ padding: "0px", backgroundColor: "transparent" }}
									onClick={() => handleModalToggle("delete", true)}

								>
									<div className="di-wrapper">
										<svg
											width="20"
											height="20"
											viewBox="0 0 22 22"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path
												fillRule="evenodd"
												clipRule="evenodd"
												d="M9.16667 9.16675C9.67293 9.16675 10.0833 9.57715 10.0833 10.0834V14.6667C10.0833 15.173 9.67293 15.5834 9.16667 15.5834C8.66041 15.5834 8.25 15.173 8.25 14.6667V10.0834C8.25 9.57715 8.66041 9.16675 9.16667 9.16675Z"
												fill="currentColor"
											/>
											<path
												fillRule="evenodd"
												clipRule="evenodd"
												d="M12.8334 9.16675C13.3397 9.16675 13.7501 9.57715 13.7501 10.0834V14.6667C13.7501 15.173 13.3397 15.5834 12.8334 15.5834C12.3272 15.5834 11.9167 15.173 11.9167 14.6667V10.0834C11.9167 9.57715 12.3272 9.16675 12.8334 9.16675Z"
												fill="currentColor"
											/>
											<path
												fillRule="evenodd"
												clipRule="evenodd"
												d="M9.16658 1.83325C7.6478 1.83325 6.41658 3.06447 6.41658 4.58325H3.66659H2.74992C2.24366 4.58325 1.83325 4.99366 1.83325 5.49992C1.83325 6.00618 2.24366 6.41658 2.74992 6.41658H3.66659V17.4166C3.66659 18.9354 4.8978 20.1666 6.41658 20.1666H15.5833C17.102 20.1666 18.3333 18.9354 18.3333 17.4166V6.41658H19.2499C19.7562 6.41658 20.1666 6.00618 20.1666 5.49992C20.1666 4.99366 19.7562 4.58325 19.2499 4.58325H18.3333H15.5833C15.5833 3.06447 14.352 1.83325 12.8333 1.83325H9.16658ZM13.7499 4.58325C13.7499 4.07699 13.3395 3.66659 12.8333 3.66659H9.16658C8.66032 3.66659 8.24992 4.07699 8.24992 4.58325H13.7499ZM6.41658 6.41658H5.49992V17.4166C5.49992 17.9228 5.91032 18.3333 6.41658 18.3333H15.5833C16.0895 18.3333 16.4999 17.9228 16.4999 17.4166V6.41658H15.5833H6.41658Z"
												fill="currentColor"
											/>
										</svg>
										<span className="dropdown-item-text">Sil</span>
									</div>
								</Dropdown.Item>
							)}
						</Dropdown.Menu>
					</Dropdown>
				</div>


				<div className="ec-body">
					<h4>{data.name}</h4>
					<p dangerouslySetInnerHTML={{ __html: truncatedContent }}></p>
				</div>

				<div className="divider-card"></div>

				<div className="ec-footer">
					<div className="ec-f_img">
						{/* {data.students.map((item, i) => (
              <img src={item} alt="Student" key={i} />
            ))} */}
					</div>

					<div className="d-flex" style={{ gap: "5px" }}>
						{data.groups && data.groups.length > 0 && data.groups.slice(0, 2).map((group, index) => (
							<span className="group-name" key={index}>
								{group.name}
							</span>
						))}
						{data.groups && data.groups.length >= 2 && (
							<span className="group-name">...</span>
						)}
					</div>

				</div>
			</div>
		</>
	);
};

export default ExerciseCard;
