import { useState } from "react";
import { Dropdown } from "react-bootstrap";
import RestoreModal from "./RestoreModal";

function DeletedTableItem({ data, tableType }) {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggleDropdown = (isOpen) => {
    setDropdownOpen(isOpen);
  };

  const [modalShowRestore, setModalShowRestore] = useState(false);

  const handleModalCloseRestore = () => setModalShowRestore(false);
  const handleModalOpenRestore = () => setModalShowRestore(true);

  return (
    <>
      <RestoreModal
        show={modalShowRestore}
        handleClose={handleModalCloseRestore}
        data={data}
        apiPath={tableType}
      />
      <tr>
        <td>
          <div className="left column-content">
            {data.image ? (
              <img src={data.image} alt="" className="avatar-image" />
            ) : (
              <div className="user-avatar">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="24"
                  height="24"
                  color="#7b7a7a"
                  fill="#7b7a7a"
                >
                  <path
                    d="M6.57757 15.4816C5.1628 16.324 1.45336 18.0441 3.71266 20.1966C4.81631 21.248 6.04549 22 7.59087 22H16.4091C17.9545 22 19.1837 21.248 20.2873 20.1966C22.5466 18.0441 18.8372 16.324 17.4224 15.4816C14.1048 13.5061 9.89519 13.5061 6.57757 15.4816Z"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M16.5 6.5C16.5 8.98528 14.4853 11 12 11C9.51472 11 7.5 8.98528 7.5 6.5C7.5 4.01472 9.51472 2 12 2C14.4853 2 16.5 4.01472 16.5 6.5Z"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    fill="#7b7a7a"
                  />
                </svg>
              </div>
            )}
          </div>
        </td>
        <td>
          <div className="column-content">{data.full_name}</div>
        </td>
        <td>
          <div className="column-content">{data.email}</div>
        </td>
        <td>
          <div className="column-content">{data.phone}</div>
        </td>
        <td>
          <div className="column-content">
            {Array.isArray(data.groups) && data.groups.length ? (
              <Dropdown className="custom-dropdown" onToggle={toggleDropdown}>
                <Dropdown.Toggle
                  className="dropdown-header"
                  id="dropdown-basic"
                >
                  <span>Qruplar</span>
                  {dropdownOpen ? (
                    <svg
                      width="10"
                      height="7"
                      viewBox="0 0 8 6"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M7.5 6C7.91202 6 8.14721 5.52962 7.9 5.2L4.4 0.533333C4.2 0.266666 3.8 0.266667 3.6 0.533333L0.1 5.2C-0.147214 5.52962 0.0879774 6 0.5 6L7.5 6Z"
                        fill="#fff"
                      />
                    </svg>
                  ) : (
                    <svg
                      width="10"
                      height="7"
                      viewBox="0 0 10 7"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M9.29269 0.333252C9.71195 0.333252 9.94504 0.818218 9.68313 1.1456L5.39011 6.51188C5.18995 6.76208 4.8094 6.76208 4.60924 6.51188L0.316218 1.1456C0.0543127 0.818217 0.2874 0.333252 0.706654 0.333252L9.29269 0.333252Z"
                        fill="white"
                      />
                    </svg>
                  )}
                </Dropdown.Toggle>

                <Dropdown.Menu className="dropdown-body">
                  {data.groups.map((group, i) => (
                    <Dropdown.Item key={i} className="dropdown-content">
                      {group.name}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            ) : (
              <span className="no-group-message">Qrup yoxdur</span>
            )}
          </div>
        </td>
        <td>
          <div className="operation-buttons right column-content">
            <button
              className="blue-btn operation-btn"
              onClick={() => handleModalOpenRestore(tableType)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="24"
                height="24"
                color="#fff"
                fill="none"
              >
                <path
                  d="M19.75 5.5L19.25 11.5M4.75 5.5L5.35461 15.5368C5.50945 18.1073 5.58688 19.3925 6.22868 20.3167C6.546 20.7737 6.9548 21.1594 7.42905 21.4493C8.01127 21.8051 8.71343 21.945 9.75 22"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                />
                <path
                  d="M11.25 15.5L12.3863 16.9657C12.958 14.8319 15.1514 13.5655 17.2852 14.1373C18.3775 14.43 19.2425 15.1475 19.75 16.0646M21.25 20.5L20.1137 19.0363C19.5419 21.1701 17.3486 22.4365 15.2147 21.8647C14.1478 21.5788 13.2977 20.8875 12.7859 20.001"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M3.25 5.5H21.25M16.3057 5.5L15.6231 4.09173C15.1696 3.15626 14.9428 2.68852 14.5517 2.39681C14.465 2.3321 14.3731 2.27454 14.277 2.2247C13.8439 2 13.3241 2 12.2845 2C11.2188 2 10.686 2 10.2457 2.23412C10.1481 2.28601 10.055 2.3459 9.96729 2.41317C9.57164 2.7167 9.35063 3.20155 8.90861 4.17126L8.30292 5.5"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                />
              </svg>
            </button>
          </div>
        </td>
      </tr>
    </>
  );
}

export default DeletedTableItem;
