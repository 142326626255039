import React from "react";
import AddGradeTable from "../components/teachers/AddGradeTable";

function AddGrade({role}) {
  return (
    <>
      <section className="add-grade">
        <AddGradeTable role={role} />
      </section>
    </>
  );
}

export default AddGrade;
