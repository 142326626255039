import React from "react";
import { Col } from "react-bootstrap";
import DeleteMessage from "./DeleteMessage";
import UserProfile from "../UserProfile";
import InboxIcon from '@mui/icons-material/Inbox';

function RequestSearch() {
  return (
    <>
      <Col xs={12} md={8}>
        <div className="request__right" style={{ height: "100%" }}>
          <div className="request__bottom"  style={{ height: "100%"}}>
            <Col xs={12} className="mb-4 d-flex align-items-center justify-content-center w-100 h-100">
              <div className="request__message d-flex justify-content-center align-items-center flex-column" style={{ height: "100%"}}>
                <InboxIcon className="icon-box" />
                <p className="icon-box-p">Müraciət yoxdur!</p>
              </div>
            </Col>
          </div>
        </div>
      </Col>
    </>
  );
}

export default RequestSearch;
