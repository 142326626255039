import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Pagination } from "@mui/material";
import Form from "react-bootstrap/Form";
import { useSelector, useDispatch } from "react-redux";
import { setSelectedGroup } from "../../store/action";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import * as consts from "../../consts/Consts";
import { createHeaders } from "../../functions/apiService"
import { fetchStudentGroups } from "../../functions/apiService";
import { handleUnauthorizedError } from "../../functions/authUtils";
import GradeTableItemStudent from "./GradeTableItemStudent";

function GradeTableStudent({ role }) {
	const navigate = useNavigate();
	const [openSelectOne, setOpenSelectOne] = useState(false);
	const [openSelectTwo, setOpenSelectTwo] = useState(false);
	const [openSelectType, setOpenSelectType] = useState(false);
	const [openSelectAttendance, setOpenSelectAttendance] = useState(false);

	const [currentWeek, setCurrentWeek] = useState(
		getCurrentWeekRange(new Date())
	);
	const [showCalendar, setShowCalendar] = useState(false);
	const [selectedDate, setSelectedDate] = useState(new Date());
	const calendarRef = useRef(null);
	const dispatch = useDispatch();
	const selectedGroup = useSelector((state) => state.selectedGroup);
	const [perPage, setPerPage] = useState(25);
	const [currentPage, setCurrentPage] = useState(1);
	const [totalPages, setTotalPages] = useState(0);
	const [getData, setGetData] = useState([]);
	const [allStudents, setAllStudents] = useState(null);
	const [selectedType, setSelectedType] = useState("daily");
	const [selectedAttendance, setSelectedAttendance] = useState(1);
	const [sortBy, setSortBy] = useState("date");
	const [sortDirection, setSortDirection] = useState("asc");

	const [groups, setGroups] = useState([]);
	const [loading, setLoading] = useState(true);
	const [openSelectGroup, setOpenSelectGroup] = useState(false);

	const handlePrevWeek = () => {
		const prevWeekStart = parseDate(currentWeek.start);
		prevWeekStart.setDate(prevWeekStart.getDate() - 7);
		setCurrentWeek(getCurrentWeekRange(prevWeekStart));
	};

	const handleNextWeek = () => {
		const nextWeekStart = parseDate(currentWeek.start);
		nextWeekStart.setDate(nextWeekStart.getDate() + 7);
		setCurrentWeek(getCurrentWeekRange(nextWeekStart));
	};

	function getCurrentWeekRange(date) {
		const today = new Date(date);
		const dayOfWeek = today.getDay();

		const startOfWeek = new Date(today);
		startOfWeek.setDate(
			today.getDate() - dayOfWeek + (dayOfWeek === 0 ? -6 : 1)
		);

		const endOfWeek = new Date(startOfWeek);
		endOfWeek.setDate(startOfWeek.getDate() + 6);

		return {
			start: formatDate(startOfWeek),
			end: formatDate(endOfWeek),
		};
	}

	function formatDate(date) {
		return date.toLocaleDateString("ru-RU", {
			day: "2-digit",
			month: "2-digit",
			year: "numeric",
		});
	}

	function parseDate(dateString) {
		const [day, month, year] = dateString.split(".");
		return new Date(`${year}-${month}-${day}`);
	}

	const handleDateChange = (date) => {
		setSelectedDate(date);
		setCurrentWeek(getCurrentWeekRange(date));
		setShowCalendar(false);
	};

	const handleToggleCalendar = () => {
		setShowCalendar((prev) => !prev);
	};

	const handleClickOutside = (event) => {
		if (calendarRef.current && !calendarRef.current.contains(event.target)) {
			setShowCalendar(false);
		}
	};

	useEffect(() => {
		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, []);

	useEffect(() => {
		if (groups && groups.length > 0) {
			if (!selectedGroup) {
				dispatch(setSelectedGroup(groups[0].id));
			}
		}
	}, [groups, selectedGroup, dispatch]);

	const getFormattedDate = () => {
		const dateToFormat = selectedDate;

		return dateToFormat.toLocaleDateString("ru-RU", {
			day: "2-digit",
			month: "2-digit",
			year: "numeric",
		});
	};

	const handleTypeChange = (e) => {
		setSelectedType(e.target.value);
	};

	const handleAttendanceChange = (e) => {
		setSelectedAttendance(e.target.value);
	};

	// useEffect(() => {
	// 	if (selectedGroup) {
	// 		const formattedDate = getFormattedDate();

	// 		const dateToSend = currentWeek.start || formattedDate;
	// 		const headers = createHeaders();

	// 		fetch(
	// 			`${consts.API_URL}/${role}/grade/get/note?pagination=${perPage}&page=${currentPage}&sort[${sortBy}]=${sortDirection}&group_id=${selectedGroup}&date=${dateToSend}&status=${selectedType}`,
	// 			{
	// 				method: "GET",
	// 				headers: headers,
	// 			}
	// 		)
	// 			.then((response) => {
	// 				if (!response.ok) {
	// 					throw new Error("Ошибка сети");
	// 				}
	// 				return response.json();
	// 			})
	// 			.then((data) => {
	// 				setGetData(data.data);
	// 				setTotalPages(data.pagination.total_pages);
	// 			})
	// 			.catch((error) => {
	// 				console.error("Ошибка при отправке запроса:", error);
	// 			});

	// 	}
	// }, [
	// 	selectedGroup,
	// 	selectedType,
	// 	selectedDate,
	// 	currentWeek.start,
	// 	perPage,
	// 	currentPage,
	// 	sortBy,
	// 	sortDirection,
	// ]);

	const handlePerPageChange = (event) => {
		setPerPage(Number(event.target.value));
		setCurrentPage(1);
	};

	const handlePageChange = (event, value) => {
		setCurrentPage(value);
	};

	const handleSortChange = (e) => {
		const value = e.target.value;
		const [field, direction] = value.split("=");
		if (field === sortBy) {
			setSortDirection((prevDirection) =>
				prevDirection === "asc" ? "desc" : "asc"
			);
		} else {
			setSortBy(field);
			setSortDirection(direction);
		}
	};

	const handleGroupChange = (e) => {
		const groupId = e.target.value;
		dispatch(setSelectedGroup(groupId));
	};

	useEffect(() => {
		const fetchData = async () => {
			try {
				const data = await fetchStudentGroups(navigate);
				setGroups(data);
			} catch (error) {
				handleUnauthorizedError(error, navigate);
			} finally {
				setLoading(false);
			}
		};

		fetchData();
	}, [navigate]);

	useEffect(() => {
		if (selectedGroup && selectedAttendance) {
			const formattedDate = getFormattedDate();

			const dateToSend = currentWeek.start || formattedDate;

			const headers = createHeaders();

			const requestParams = new URLSearchParams({
				group_id: selectedGroup,
				date: dateToSend,
				attendance: +selectedAttendance,
				status: selectedType,
				pagination: perPage,
				// page: currentPage,
				// ...(sortBy ? { [sortBy]: sortDirection } : {}), 
			});

			fetch(`${consts.API_URL}/student/grade?${requestParams.toString()}`, {
				method: "GET",
				headers: headers,
			})
				.then((response) => {
					if (!response.ok) {
						throw new Error("Ошибка сети");
					}
					return response.json();
				})
				.then((data) => {
					setGetData(data.data);
					setTotalPages(data.pagination.total_pages);
				})
				.catch((error) => {
					// console.error("Ошибка при отправке запроса:", error);
				});

			// Второй запрос для получения всех студентов
			const allStudentsParams = new URLSearchParams({
				group_id: selectedGroup,
				date: dateToSend,
				attendance: +selectedAttendance,
				status: selectedType,
				// pagination: perPage,
			});

			fetch(`${consts.API_URL}/student/grade?${allStudentsParams.toString()}`, {
				method: "GET",
				headers: headers,
			})
				.then((response) => {
					if (!response.ok) {
						throw new Error("Ошибка сети");
					}
					return response.json();
				})
				.then((data) => {
					setAllStudents(data);
				})
				.catch((error) => {
					// console.error("Ошибка при отправке запроса:", error);
				});
		}
	}, [
		selectedGroup,
		selectedType,
		selectedAttendance,
		selectedDate,
		currentWeek.start,
		// perPage,
		// currentPage,
		// sortBy,
		// sortDirection,
	]);

	return (
		<>
			<div className="form-performance mt-5 mb-5">
				<h3 className="title">Performans cədvəli</h3>
				<div className="groups">
					<div className="groups-header groups-header-grade d-block">
						{/* <div className="header-left">
					<h3 className="title mb-3">Müəllimin qeydləri</h3>
				</div> */}
						<div className="header-right justify-content-between">
							<div className="group-subject d-flex align-items-center">
								<Form.Select
									className={`selection ${openSelectAttendance ? "active" : ""}`}
									onClick={() => setOpenSelectAttendance((prev) => !prev)}
									onChange={handleAttendanceChange}
								>
									<option value="1">Qiymət</option>
									<option value="0">Davamiyyət</option>
								</Form.Select>
							</div>
							<div className="d-flex align-items-center grade-week">
								<div className="group-subject d-flex align-items-center">
									<p className="group-subject-name">Qiymət tipi: </p>
									<Form.Select
										className={`selection ${openSelectType ? "active" : ""}`}
										onClick={() => setOpenSelectType((prev) => !prev)}
										onChange={handleTypeChange}
									>
										<option value="daily">Günlük</option>
										<option value="exam">İmtahan</option>
										<option value="quiz">Quiz</option>
										<option value="project">Disiplin</option>
									</Form.Select>
								</div>
								<div className="header-body-right d-flex align-items-center header-right">
									<div className="week-navigation">
										<button onClick={handlePrevWeek}>
											<svg
												width="7"
												height="12"
												viewBox="0 0 7 12"
												fill="none"
												xmlns="http://www.w3.org/2000/svg"
											>
												<path
													fillRule="evenodd"
													clipRule="evenodd"
													d="M1.8938 6.11523L6.04779 1.93164C6.35407 1.62207 6.35407 1.12402 6.04779 0.814453C5.90137 0.666016 5.70148 0.582031 5.4928 0.582031C5.28412 0.582031 5.08423 0.666016 4.93781 0.814453L0.227783 5.55664C-0.0759277 5.86816 -0.0759277 6.36426 0.227783 6.67578L4.93781 11.418C5.08447 11.5654 5.2843 11.6494 5.4928 11.6494C5.70129 11.6494 5.90112 11.5654 6.04779 11.418C6.3515 11.1064 6.3515 10.6104 6.04779 10.2998L1.8938 6.11523Z"
													fill="#707070"
												/>
											</svg>
										</button>
										<button className="week-current" onClick={handleToggleCalendar}>
											{currentWeek.start} - {currentWeek.end}
										</button>
										<button onClick={handleNextWeek}>
											<svg
												width="7"
												height="12"
												viewBox="0 0 7 12"
												fill="none"
												xmlns="http://www.w3.org/2000/svg"
											>
												<path
													fillRule="evenodd"
													clipRule="evenodd"
													d="M4.38379 6.11523L0.229736 1.93164C-0.0765381 1.62207 -0.0765381 1.12402 0.229736 0.814453C0.376221 0.666016 0.576172 0.582031 0.78479 0.582031C0.993408 0.582031 1.19336 0.666016 1.33972 0.814453L6.04968 5.55664C6.35339 5.86816 6.35339 6.36426 6.04968 6.6748L1.33972 11.418C1.19312 11.5654 0.993286 11.6494 0.78479 11.6494C0.576294 11.6494 0.376465 11.5654 0.229858 11.418C-0.0737305 11.1064 -0.0738526 10.6104 0.229858 10.2998L4.38379 6.11523Z"
													fill="#707070"
												/>
											</svg>
										</button>
									</div>
									{/* <Form.Select
									className={`selection ${openSelectOne ? "active" : ""}`}
									onClick={() => setOpenSelectOne((prev) => !prev)}
									onChange={handleSortChange}
									value={`${sortBy}${sortDirection ? `=${sortDirection}` : ""}`}
								>
									<option value="date=asc">Tarix A-Z</option>
									<option value="date=desc">Tarix Z-A</option>
								</Form.Select> */}
								</div>
							</div>
						</div>
					</div>
					<div className="groups-body">
						<table>
							<thead>
								<tr>
									<th>
										<div className="left column-content">
											Tarix
										</div>
									</th>
									<th>
										<div className="column-content" >
											Qiymət
										</div>
									</th>
									<th>
										<div className="column-content">Müəllim</div>
									</th>
									<th>
										<div className="column-content">Qiymət tipi</div>
									</th>
								</tr>
							</thead>
							<tbody>
								{getData.grades?.map((student, i) => (
									<GradeTableItemStudent data={student} key={i} role={role} />
								))}
							</tbody>
						</table>
					</div>
					{/* <div className="groups-footer">
						<div className="groups-pagination d-flex">
							<Pagination
								count={totalPages}
								page={currentPage}
								onChange={handlePageChange}
								className="pagination"
								variant="outlined"
								shape="rounded"
							/>

							<Form.Select
								className={`selection ${openSelectTwo ? "active" : ""}`}
								onClick={() => setOpenSelectTwo((prev) => !prev)}
								onChange={handlePerPageChange}
							>
								<option value="10">10</option>
								<option value="25">25</option>
								<option value="50">50</option>
								<option value="100">100</option>
							</Form.Select>
						</div>
					</div> */}
					{showCalendar && (
						<div className="calendar-popup">
							<DatePicker
								selected={selectedDate}
								onChange={handleDateChange}
								inline
							/>
						</div>
					)}
				</div>
			</div>
		</>
	);
}

export default GradeTableStudent;
