// tokenService.js
export const setCookie = (name, value, days) => {
	const expires = new Date(Date.now() + days * 864e5).toUTCString();
	document.cookie = `${name}=${value}; expires=${expires}; path=/`;
};

export const saveTokensToCookie = (accessToken, refreshToken, expiresIn) => {
	setCookie('access_token', accessToken, expiresIn / 86400); // Сохраняем на время действия токена
	setCookie('refresh_token', refreshToken, 30);  // refresh_token сохраняется на 30 дней
};


// tokenUtils.js
export const getCookie = (name) => {
	const value = `; ${document.cookie}`;
	const parts = value.split(`; ${name}=`);
	if (parts.length === 2) return parts.pop().split(';').shift();
 };
 
 export const getApiToken = () => {
	return getCookie('access_token');
 };
 