import React, { useState } from "react";
import Form from "react-bootstrap/Form";

const FilterSelect = ({ data, onSelect }) => {
  const [openSelectOne, setOpenSelectOne] = useState(false);

  const handleSelectChange = (event) => {
    const selectedValue = event.target.value;
    onSelect(selectedValue); // Pass the selected field to the parent component
    setOpenSelectOne(false); // Close the dropdown
  };

  return (
    <>
      <Form.Select
        className={`selection ${openSelectOne ? "active" : ""}`}
        onClick={() => setOpenSelectOne((prev) => !prev)}
        onChange={handleSelectChange}
      >
        <option value="">Sırala</option>
        {data.map((option) => (
          <React.Fragment key={option.value}>
            <option value={`${option.value} asc`}>
              {option.name} [A-Z]
            </option>
            <option value={`${option.value} desc`}>
              {option.name} [Z-A]
            </option>
          </React.Fragment>
        ))}
      </Form.Select>
    </>
  );
};

export default FilterSelect;
