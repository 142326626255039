// Импортируем необходимые функции
import { saveTokensToCookie, clearTokens, getRefreshTokenFromCookie } from './tokenService';
import { parseJwt } from './tokenService';
import * as consts from "../consts/Consts";

let isRefreshing = false; // Флаг, чтобы предотвратить повторные запросы

// Функция для обновления access_token с использованием refresh_token
export const refreshAccessToken = async (navigate) => {
	if (isRefreshing) return; // Если уже выполняется запрос, прерываем

	isRefreshing = true; // Устанавливаем флаг

	try {
		const refreshToken = getRefreshTokenFromCookie();
		if (!refreshToken) {
			throw new Error('Refresh token отсутствует');
		}

		const decodedToken = parseJwt(refreshToken);
		const currentTime = Date.now() / 1000;

		if (decodedToken.exp < currentTime) {
			throw new Error('Refresh token истек');
		}

		const response = await fetch(`${consts.API_URL}/auth/refresh`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${refreshToken}`, // Отправляем refresh_token в заголовках
			},
		});

		if (!response.ok) {
			throw new Error('Ошибка при обновлении токена');
		}

		const responseData = await response.json();
		const { access_token, refresh_token, expires_in } = responseData.data;

		// Сохраняем новые токены
		saveTokensToCookie(access_token, refresh_token, expires_in);
		return access_token;
	} catch (error) {
		clearTokens();
		navigate('/');
		throw error; // Пробрасываем ошибку для дальнейшей обработки
	} finally {
		isRefreshing = false; // Сбрасываем флаг после завершения
	}
};


// Обработка неавторизованного доступа
export const handleUnauthorizedResponse = (navigate) => {
	clearTokens();
	navigate('/');
};
