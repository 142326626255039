import React, { useState, useEffect } from "react";
import { Modal, Button, Form, Dropdown } from "react-bootstrap";
import * as consts from "../../consts/Consts";
import { createHeaders } from "../../functions/apiService";

function AddNoteModal({ show, handleClose, note, setNote, role }) {
  const [searchTerm, setSearchTerm] = useState(note || ""); 
  const [results, setResults] = useState([]); 
  const [allNotes, setAllNotes] = useState([]); 
  const [showDropdown, setShowDropdown] = useState(false); 
  const [page, setPage] = useState(1); 
  const [hasMore, setHasMore] = useState(true); 
  const [loading, setLoading] = useState(false); 
  
  const loadNotes = (pageNum, searchTerm = "", allNotes = []) => {
    if (loading) return; 
    
    setLoading(true); 
    
    const headers = createHeaders();
    fetch(`${consts.API_URL}/note-template?pagination=5&note=${searchTerm}&page=${pageNum}`, {
      method: "GET",
      headers: headers,
    })
      .then((response) => response.json())
      .then((data) => {
        const notes = Array.isArray(data.data) ? data.data : [];
        const pagination = data.pagination || {};
        const { current_page, total_pages } = pagination;
          const updatedNotes = [...allNotes, ...notes];
        setAllNotes(updatedNotes);
        setPage(current_page); 
  
        if (current_page < total_pages) {
          loadNotes(current_page + 1, searchTerm, updatedNotes);
        } else {
          setHasMore(false); 
        }
  
        setLoading(false); 
      })
      .catch((error) => {
        console.error("Error fetching note templates:", error);
        setLoading(false); 
      });
  };
  
  
  useEffect(() => {
    if (searchTerm.length > 0) {
      setAllNotes([]);
      setPage(1);
      setHasMore(true);
      loadNotes(1, searchTerm);
    }
  }, [searchTerm]);
  
  const handleSearch = (e) => {
    const value = e.target.value;
    setSearchTerm(value);
    setAllNotes([]);
    setPage(1);
    setHasMore(true);
    loadNotes(1, value);
    setShowDropdown(value.length > 0);
  };

  const handleSelect = (selectedNote) => {
    setSearchTerm(selectedNote); 
    setShowDropdown(false); 
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setNote(searchTerm); 
    handleClose();
  };

  const handleScroll = (e) => {
    const { scrollTop, scrollHeight, clientHeight } = e.target;
  
    if (hasMore && !loading) {
      loadNotes(page + 1, searchTerm); 
    }
  };
  

  return (
    <>
      <Modal show={show} onHide={handleClose} className="note-modal">
        <Modal.Header closeButton></Modal.Header>
        <Form onSubmit={handleSubmit}>
          <Modal.Body>
            <Form.Group controlId="exampleForm.ControlTextarea1">
              <Form.Label className="note-midal-label">Qeyd əlavə et</Form.Label>
              <Form.Control
                as="textarea"
                name="note"
                rows={1}
                placeholder="Mesajınızı yazın..."
                value={searchTerm}
                onChange={handleSearch} 
              />
              {showDropdown && allNotes.length > 0 && (
                <Dropdown.Menu show className="w-100" onScroll={handleScroll}>
                  {allNotes.map((result, index) => (
                   <Dropdown.Item
                   key={index}
                   onClick={() => handleSelect(result.note)} 
                 >
                   {result.note} 
                 </Dropdown.Item>
                 
                  ))}
                </Dropdown.Menu>
              )}
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button type="submit" className="note-btn modal-btn btn-green">
              Qeyd əlavə et
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
}

export default AddNoteModal;
