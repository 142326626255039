import React, { useState } from "react";

function GradeTableItemStudent({ data, role }) {

	return (
		<>
			<tr>
				<td>
					<div className="left column-content">{data.date}</div>
				</td>
				<td>
					<div
						className="column-content"

					>
						<span className="column-content-grade"
							style={{
								color: data.grade ? "#141518" : "#FFFFFF",
								backgroundColor: data.grade
									? "#1AE5BE"
									: data.attendance === 1
										? "#246CF9"
										: data.attendance === 0
											? "#FC5A5A"
											: ""
							}}
						>
							{data.grade ? data.grade : !data.grade && data.attendance === 1 ? "+" : !data.grade && data.attendance === 0 ? "Q" : ""}
						</span>
					</div>
				</td>
				<td>
					<div className="column-content">{data.group.teacher.full_name}</div>
				</td>
				<td>
					<div className="column-content">
						{data && data.status === 'daily' ? 'Gündəlik' :
							data && data.status === 'quiz' ? 'Quiz' :
								data && data.status === 'project' ? 'Layihə' :
									data && data.status === 'exam' ? 'İmtahan' :
										"Məlumat tapılmadı"
						}
					</div>

				</td>
			</tr>
		</>
	);
}

export default GradeTableItemStudent;
