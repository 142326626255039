import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { Provider } from "react-redux";
import { legacy_createStore } from "redux";
import Reducer from "./store/Reducer";
import { BrowserRouter as Router } from "react-router-dom";
import { ProSidebarProvider } from "react-pro-sidebar"; // Импортируем ProSidebarProvider

const store = legacy_createStore(Reducer);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <Router>
      <ProSidebarProvider>
        {" "}
        {/* Оборачиваем App в ProSidebarProvider */}
        <App />
      </ProSidebarProvider>
    </Router>
  </Provider>
);
