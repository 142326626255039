import React from "react";
import StudentTable from "../components/admin/StudentTable";

function Students() {
  return (
    <section>
      <h3 className="title mb-3">Tələbələr</h3>
      <StudentTable />
    </section>
  );
}

export default Students;
