import { useEffect } from 'react';
import { getAccessTokenFromCookie, getRefreshTokenFromCookie, isTokenExpired, parseJwt } from './tokenService';
import { refreshAccessToken } from './authService';
import { clearTokens } from './tokenService';

const useAutoRefreshToken = () => {
	useEffect(() => {
		const refreshTokenBeforeExpiry = async () => {
			const accessToken = getAccessTokenFromCookie();
			const refreshToken = getRefreshTokenFromCookie();

			if (!accessToken || !refreshToken) {
				return;
			}

			// Проверяем, истекает ли accessToken
			if (isTokenExpired(accessToken, 15)) { // Проверяем за 15 секунд до истечения
				try {
					await refreshAccessToken(refreshToken);
				} catch (error) {
					// console.error('Не удалось обновить access_token:', error);
					clearTokens();  // Очищаем токены при ошибке
				}
			}
		};

		// Проверка каждые 1 минуту
		const interval = setInterval(() => {
			refreshTokenBeforeExpiry();
		}, 60 * 1000);

		// Первоначальная проверка при монтировании компонента
		refreshTokenBeforeExpiry();

		return () => clearInterval(interval);
	}, []);
};

export default useAutoRefreshToken;
