import { useState, useEffect } from "react";

const WeekPagination = ({ active, setActiveDay }) => {
	const weekDay = [
		{
			name: "B.e",
			id: 1,
		},
		{
			name: "Ç.a.",
			id: 2,
		},
		{
			name: "Ç.",
			id: 3,
		},
		{
			name: "C.a.",
			id: 4,
		},
		{
			name: "C.",
			id: 5,
		},
		{
			name: "Ş.",
			id: 6,
		},
		{
			name: "B.",
			id: 7,
		},
	];

	useEffect(() => {
		const date = new Date();
		const weekday = date.getDay() === 0 ? 7 : date.getDay();
		setActiveDay(weekday);
	}, [setActiveDay]);
	// const [active, setActive] = useState(currentWeekDay());

	return (
		<>
			<div className="week-pagination">
				<button
					className="prev wp_button"
					onClick={() =>
						setActiveDay((prev) => {
							if (prev > 1) {
								return prev - 1;
							} else {
								return 7;
							}
						})
					}
				>
					<svg
						width="11"
						height="19"
						viewBox="0 0 11 19"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							fillRule="evenodd"
							clipRule="evenodd"
							d="M3.3185 9.50084L10.5975 16.6831C11.1342 17.2145 11.1342 18.0695 10.5975 18.601C10.341 18.8558 9.99069 19 9.62502 19C9.25936 19 8.90909 18.8558 8.65251 18.601L0.399145 10.4598C-0.133047 9.925 -0.133047 9.07333 0.399145 8.53851L8.65251 0.397334C8.90952 0.144179 9.25968 -1.09784e-06 9.62503 -1.0659e-06C9.99038 -1.03396e-06 10.3405 0.144179 10.5975 0.397334C11.1297 0.932143 11.1297 1.78381 10.5975 2.31695L3.3185 9.50084Z"
							fill="#CCCCCC"
						/>
					</svg>
				</button>

				<div className="wp_day">
					{weekDay.map((item) => (
						<div className="wp_day-inner" key={item.id}>
							<span className="wp_day-name">{item.name}</span>
							<button
								className={`wp_day-button ${active === item.id ? "active_button" : ""
									}`}
								onClick={() => setActiveDay(item.id)}
							>
								<span>{item.id}</span>
							</button>
						</div>
					))}
				</div>

				<button
					className="next wp_button"
					onClick={() =>
						setActiveDay((prev) => {
							if (prev < 7) {
								return prev + 1;
							} else {
								return 1;
							}
						})
					}
				>
					<svg
						width="11"
						height="19"
						viewBox="0 0 11 19"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							fillRule="evenodd"
							clipRule="evenodd"
							d="M7.6815 9.50084L0.402461 16.6831C-0.134224 17.2145 -0.134224 18.0695 0.402461 18.601C0.659039 18.8558 1.00931 19 1.37498 19C1.74064 19 2.09091 18.8558 2.34749 18.601L10.6009 10.4598C11.133 9.925 11.133 9.07333 10.6009 8.53851L2.34749 0.397334C2.09048 0.144179 1.74032 -1.09784e-06 1.37497 -1.0659e-06C1.00962 -1.03396e-06 0.659464 0.144179 0.402459 0.397334C-0.129734 0.932143 -0.129734 1.78381 0.40246 2.31695L7.6815 9.50084Z"
							fill="#CCCCCC"
						/>
					</svg>
				</button>
			</div>
		</>
	);
};

export default WeekPagination;
