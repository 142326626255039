import { useState } from "react";
import { Dropdown } from "react-bootstrap";
import EditStudent from "./EditStudent";
import AlertModal from "./AlertModal";
import DeleteModal from "./DeleteModal";
import BlockModal from "./BlockModal";
import UserProfile from "../UserProfile";

function GraduatesTableItem({ data }) {
	const [dropdownOpen, setDropdownOpen] = useState(false);
	const toggleDropdown = (isOpen) => {
		setDropdownOpen(isOpen);
	};
	const [modalShowEdit, setModalShowEdit] = useState(false);
	const [modalShowGraduate, setModalShowGraduate] = useState(false);
	const [modalShowBlock, setModalShowBlock] = useState(false);
	const [modalShowDelete, setModalShowDelete] = useState(false);

	const handleModalShowEdit = () => setModalShowEdit(true);
	const handleModalCloseEdit = () => setModalShowEdit(false);

	const handleModalCloseGraduate = () => setModalShowGraduate(false);
	const handleModalOpenGraduate = () => setModalShowGraduate(true);

	const handleModalCloseBlock = () => setModalShowBlock(false);
	const handleModalOpenBlock = () => setModalShowBlock(true);

	const handleModalCloseDelete = () => setModalShowDelete(false);
	const handleModalOpenDelete = () => setModalShowDelete(true);
	const [isBlocked, setIsBlocked] = useState(data.is_blocked || false);

	return (
		<>
			<AlertModal
				show={modalShowGraduate}
				handleClose={handleModalCloseGraduate}
				data={data}
				page={"student"}
			/>
			<BlockModal
				show={modalShowBlock}
				handleClose={handleModalCloseBlock}
				data={data}
				apiPath={"student"}
				setIsBlocked={setIsBlocked}
				isBlocked={isBlocked}
			/>
			<DeleteModal
				show={modalShowDelete}
				handleClose={handleModalCloseDelete}
				data={data}
				apiPath={"student"}
			/>
			<EditStudent
				show={modalShowEdit}
				handleClose={handleModalCloseEdit}
				data={data}
			/>
			<tr>
				<td>
					<div className="left column-content">
						{data.image ? (
							<img src={data.image} alt="" className="avatar-image" />
						) : (
							<UserProfile imageSize={24} containerSize={32} />
						)}
					</div>
				</td>
				<td>
					<div className="column-content">{data.full_name}</div>
				</td>
				<td>
					<div className="column-content">{data.email}</div>
				</td>
				<td>
					<div className="column-content">{data.phone}</div>
				</td>
				<td>
					<div className="column-content">
						{Array.isArray(data.groups) && data.groups.length ? (
							<Dropdown className="custom-dropdown" onToggle={toggleDropdown}>
								<Dropdown.Toggle
									className="dropdown-header"
									id="dropdown-basic"
								>
									<span>Qruplar</span>
									{dropdownOpen ? (
										<svg
											width="10"
											height="7"
											viewBox="0 0 8 6"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path
												fillRule="evenodd"
												clipRule="evenodd"
												d="M7.5 6C7.91202 6 8.14721 5.52962 7.9 5.2L4.4 0.533333C4.2 0.266666 3.8 0.266667 3.6 0.533333L0.1 5.2C-0.147214 5.52962 0.0879774 6 0.5 6L7.5 6Z"
												fill="#fff"
											/>
										</svg>
									) : (
										<svg
											width="10"
											height="7"
											viewBox="0 0 10 7"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path
												fillRule="evenodd"
												clipRule="evenodd"
												d="M9.29269 0.333252C9.71195 0.333252 9.94504 0.818218 9.68313 1.1456L5.39011 6.51188C5.18995 6.76208 4.8094 6.76208 4.60924 6.51188L0.316218 1.1456C0.0543127 0.818217 0.2874 0.333252 0.706654 0.333252L9.29269 0.333252Z"
												fill="white"
											/>
										</svg>
									)}
								</Dropdown.Toggle>

								<Dropdown.Menu className="dropdown-body">
									{data.groups.map((group, i) => (
										<Dropdown.Item key={i} className="dropdown-content">
											{group.name}
										</Dropdown.Item>
									))}
								</Dropdown.Menu>
							</Dropdown>
						) : (
							<span className="no-group-message">Qrup yoxdur</span>
						)}
					</div>
				</td>

				<td>
					<div className="operation-buttons right column-content">
						<button
							className="green-btn operation-btn"
							onClick={handleModalShowEdit}
						>
							<svg
								width="18"
								height="18"
								viewBox="0 0 18 18"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M2.9893 15.8934L6.96677 15.0979C7.11197 15.0689 7.24532 14.9975 7.35002 14.8928L12.3374 9.90543C12.6303 9.61254 12.6303 9.13766 12.3374 8.84477L9.15539 5.66279C8.86249 5.3699 8.38761 5.3699 8.09472 5.66279L3.10738 10.6502C3.00268 10.7549 2.93131 10.8882 2.90227 11.0334L2.10678 15.0109C2.00182 15.5357 2.46451 15.9984 2.9893 15.8934Z"
									fill="white"
								/>
								<path
									d="M15.1444 3.91645L14.0837 2.85579C13.2051 1.97711 11.7804 1.97711 10.9018 2.85579L10.1628 3.59475C9.86991 3.88764 9.86991 4.36252 10.1628 4.65541L13.3448 7.83739C13.6377 8.13029 14.1125 8.13028 14.4054 7.83739L15.1444 7.09843C16.0231 6.21975 16.0231 4.79513 15.1444 3.91645Z"
									fill="white"
								/>
							</svg>
						</button>

						<button
							className="blue-btn operation-btn"
							onClick={() => handleModalOpenGraduate(data.name)}
						>
							<svg
								width="22"
								height="22"
								viewBox="0 0 22 22"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M1 11H10C10 8.79086 7.98528 7 5.5 7C3.01472 7 1 8.79086 1 11Z"
									stroke="white"
									strokeWidth="1.5"
								/>
								<path
									d="M7.49873 3C7.49873 4.10457 6.6033 5 5.49873 5C4.39416 5 3.49873 4.10457 3.49873 3C3.49873 1.89543 4.39416 1 5.49873 1C6.6033 1 7.49873 1.89543 7.49873 3Z"
									stroke="white"
									strokeWidth="1.5"
								/>
								<path
									d="M12 21H21C21 18.7909 18.9853 17 16.5 17C14.0147 17 12 18.7909 12 21Z"
									stroke="white"
									strokeWidth="1.5"
								/>
								<path
									d="M18.4987 13C18.4987 14.1046 17.6033 15 16.4987 15C15.3942 15 14.4987 14.1046 14.4987 13C14.4987 11.8954 15.3942 11 16.4987 11C17.6033 11 18.4987 11.8954 18.4987 13Z"
									stroke="white"
									strokeWidth="1.5"
								/>
								<path
									d="M3 14C3 17.3171 5.68286 20 9 20L8.5 17.5"
									stroke="white"
									strokeWidth="1.5"
									strokeLinejoin="round"
								/>
								<path
									d="M19 8C19 4.68286 16.3171 2 13 2L13.5 4.5"
									stroke="white"
									strokeWidth="1.5"
									strokeLinejoin="round"
								/>
							</svg>
						</button>

						<button
							className="light-gray-btn operation-btn"
							onClick={() => {
								handleModalOpenBlock();
							}}
						>
							{data.is_blocked === 1 ? (
								<svg
									xmlns="http://www.w3.org/2000/svg"
									viewBox="0 0 24 24"
									width="24"
									height="24"
									color="#246cf9"
									fill="none"
								>
									<path
										d="M16.5312 16.36V14.9523C16.5312 13.8741 17.4127 13 18.5 13C18.9887 13 19.4359 13.1766 19.7801 13.469M17.8437 22H19.1563C20.1777 22 20.6884 22 21.0749 21.7951C21.3802 21.6333 21.6302 21.3854 21.7934 21.0827C22 20.6993 22 20.1929 22 19.18C22 18.1671 22 17.6607 21.7934 17.2773C21.6302 16.9746 21.3802 16.7267 21.0749 16.5649C20.6884 16.36 20.1777 16.36 19.1563 16.36H17.8437C16.8223 16.36 16.3116 16.36 15.9251 16.5649C15.6198 16.7267 15.3698 16.9746 15.2066 17.2773C15 17.6607 15 18.1671 15 19.18C15 20.1929 15 20.6993 15.2066 21.0827C15.3698 21.3854 15.6198 21.6333 15.9251 21.7951C16.3116 22 16.8223 22 17.8437 22Z"
										stroke="currentColor"
										strokeWidth="1.5"
										strokeLinecap="round"
									/>
									<path
										d="M12.5 22H6.59087C5.04549 22 3.81631 21.248 2.71266 20.1966C0.453365 18.0441 4.1628 16.324 5.57757 15.4816C7.97679 14.053 10.8425 13.6575 13.5 14.2952"
										stroke="currentColor"
										strokeWidth="1.5"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
									<path
										d="M16.5 6.5C16.5 8.98528 14.4853 11 12 11C9.51472 11 7.5 8.98528 7.5 6.5C7.5 4.01472 9.51472 2 12 2C14.4853 2 16.5 4.01472 16.5 6.5Z"
										stroke="currentColor"
										strokeWidth="1.5"
									/>
								</svg>
							) : (
								<svg
									width="22"
									height="22"
									viewBox="0 0 22 22"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M11.9168 20.1666H6.04179C4.6252 20.1666 3.49845 19.4773 2.48677 18.5134C0.415747 16.5404 3.81607 14.9636 5.11294 14.1914C7.31222 12.8818 9.93913 12.5193 12.3752 13.1038"
										stroke="#FC5A5A"
										strokeWidth="1.5"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
									<path
										d="M15.125 5.95825C15.125 8.23643 13.2782 10.0833 11 10.0833C8.72183 10.0833 6.875 8.23643 6.875 5.95825C6.875 3.68008 8.72183 1.83325 11 1.83325C13.2782 1.83325 15.125 3.68008 15.125 5.95825Z"
										stroke="#FC5A5A"
										strokeWidth="1.5"
									/>
									<path
										d="M14.7125 14.7125L19.2042 19.2042M20.1667 16.9583C20.1667 15.1864 18.7302 13.75 16.9583 13.75C15.1864 13.75 13.75 15.1864 13.75 16.9583C13.75 18.7302 15.1864 20.1667 16.9583 20.1667C18.7302 20.1667 20.1667 18.7302 20.1667 16.9583Z"
										stroke="#FC5A5A"
										strokeWidth="1.5"
										strokeLinecap="round"
									/>
								</svg>
							)}
						</button>

						<button
							className="red-btn operation-btn"
							onClick={() => handleModalOpenDelete(data.name)}
						>
							<svg
								width="22"
								height="22"
								viewBox="0 0 22 22"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									fillRule="evenodd"
									clipRule="evenodd"
									d="M9.16667 9.16675C9.67293 9.16675 10.0833 9.57715 10.0833 10.0834V14.6667C10.0833 15.173 9.67293 15.5834 9.16667 15.5834C8.66041 15.5834 8.25 15.173 8.25 14.6667V10.0834C8.25 9.57715 8.66041 9.16675 9.16667 9.16675Z"
									fill="white"
								/>
								<path
									fillRule="evenodd"
									clipRule="evenodd"
									d="M12.8334 9.16675C13.3397 9.16675 13.7501 9.57715 13.7501 10.0834V14.6667C13.7501 15.173 13.3397 15.5834 12.8334 15.5834C12.3272 15.5834 11.9167 15.173 11.9167 14.6667V10.0834C11.9167 9.57715 12.3272 9.16675 12.8334 9.16675Z"
									fill="white"
								/>
								<path
									fillRule="evenodd"
									clipRule="evenodd"
									d="M9.16658 1.83325C7.6478 1.83325 6.41658 3.06447 6.41658 4.58325H3.66659H2.74992C2.24366 4.58325 1.83325 4.99366 1.83325 5.49992C1.83325 6.00618 2.24366 6.41658 2.74992 6.41658H3.66659V17.4166C3.66659 18.9354 4.8978 20.1666 6.41658 20.1666H15.5833C17.102 20.1666 18.3333 18.9354 18.3333 17.4166V6.41658H19.2499C19.7562 6.41658 20.1666 6.00618 20.1666 5.49992C20.1666 4.99366 19.7562 4.58325 19.2499 4.58325H18.3333H15.5833C15.5833 3.06447 14.352 1.83325 12.8333 1.83325H9.16658ZM13.7499 4.58325C13.7499 4.07699 13.3395 3.66659 12.8333 3.66659H9.16658C8.66032 3.66659 8.24992 4.07699 8.24992 4.58325H13.7499ZM6.41658 6.41658H5.49992V17.4166C5.49992 17.9228 5.91032 18.3333 6.41658 18.3333H15.5833C16.0895 18.3333 16.4999 17.9228 16.4999 17.4166V6.41658H15.5833H6.41658Z"
									fill="white"
								/>
							</svg>
						</button>
					</div>
				</td>
			</tr>
		</>
	);
}

export default GraduatesTableItem;
