import UserProfile from "../UserProfile";

function TeachersActivityTableItem({ data }) {
  console.log(data);
  
  return (
    <>
      <tr>
        <td>
          <div className="left column-content">
          {data.image ? (
              <img src={data.image} alt="" className="avatar-image" />
            ) : (
              <UserProfile imageSize={24} containerSize={32} />
            )}          
            </div>
        </td>
        <td>
          <div className="column-content">{data.full_name}</div>
        </td>
        <td>
          <div className="column-content">{data.created_at}</div>
        </td>
        <td>
          <div className="column-content right">{data.description}</div>
        </td>
      </tr>
    </>
  );
}

export default TeachersActivityTableItem;
