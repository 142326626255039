import React from "react";
import { Col } from "react-bootstrap";
import UserProfile from "../UserProfile";

function RequestItem({ request, onClick }) {
  const fullName = request.teacher
    ? request.teacher.full_name
    : request.student
      ? request.student.full_name
      : "Unknown"

  const image = request.teacher
    ? request.teacher.image
    : request.student
      ? request.student.image
      : "Unknown"

  const statusClass = request.status === 0 ? "unread" : "read";

  return (
    <Col xs={12} className="mb-4 request-message-col" onClick={onClick}>
      <div className="request__item d-flex">
        <div className="request-item__img">
          {image ? (
            <img src={image} alt="Profile" />
          ) : (
            <div>
              <UserProfile imageSize={45} containerSize={60} />
            </div>
          )}        </div>
        <div className="request-item__info d-flex flex-column justify-content-between">
          <div className="request-item__info d-flex justify-content-between">
            <p className="request-item__name">{fullName}</p>
            <p className="request-item__date">{request.created_at}</p>
          </div>
          <p className="request-item__message">{request.theme}</p>
        </div>
        <div className={`request-item__icon ${statusClass}`}></div>
      </div>
    </Col>
  );
}

export default RequestItem;
