import DraftersTable from "../components/admin/DraftersTable";

function WaitingProject() {
  return (
    <>
      <section>
        <h3 className="title mb-3">Layihə gözləyənlər</h3>
        <DraftersTable />
      </section>
    </>
  );
}

export default WaitingProject;
