import AboutUs from "../components/admin/AboutUs";

function About() {
  return (
    <>
      <section>
        <h3 className="title mb-3">Haqqımızda</h3>
        <AboutUs/>
      </section>
    </>
  );
}

export default About;
