import React, { useState } from "react";
import OnlineLessonModal from "./OnlineLessonModal";
import UserProfile from "../UserProfile";

function UserInfo({ getData }) {
	const [show, setShow] = useState(false);

	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);

	return (
		<>
			<OnlineLessonModal show={show} handleClose={handleClose} />
			<div className="user__top  mb-5">
				<div className="user__img">
					{getData?.image ? (
						<img src={getData.image} alt="" className="profile-image-size" />
					) : (
						<UserProfile imageSize={90} containerSize={110} />
					)}
				</div>
				<div className="user__info">
					<h3 className="user__name">{getData?.full_name}</h3>
					<p className="user__email">{getData?.email}</p>
					<p className="user__access">Müəllim: {getData?.specialty}</p>
					<button className="user__lesson" onClick={handleShow}>
						<svg
							width="18"
							height="18"
							viewBox="0 0 18 18"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M9.75 8.2501L15.9 2.1001"
								stroke="#98ABFF"
								strokeWidth="1.5"
								strokeLinecap="round"
								strokeLinejoin="round"
							/>
							<path
								d="M16.5 5.1V1.5H12.9"
								stroke="#98ABFF"
								strokeWidth="1.5"
								strokeLinecap="round"
								strokeLinejoin="round"
							/>
							<path
								d="M8.25 1.5H6.75C3 1.5 1.5 3 1.5 6.75V11.25C1.5 15 3 16.5 6.75 16.5H11.25C15 16.5 16.5 15 16.5 11.25V9.75"
								stroke="#98ABFF"
								strokeWidth="1.5"
								strokeLinecap="round"
								strokeLinejoin="round"
							/>
						</svg>
						<span>ONLAYN DƏRS YARAT</span>
					</button>
				</div>
			</div>
		</>
	);
}

export default UserInfo;
